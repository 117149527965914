/*Top banner*/
.top-banner {
  height: 630px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center; /* Center the content horizontally */
  overflow: hidden;
  z-index: 1;
  background-image: url("assets/images/mirrored_bannerimg.webp");
  background-size: cover; /* Cover the entire element */
  background-repeat: no-repeat; /* Do not repeat the image */
  padding-top: 80px; /* Adjusted for the header height */
  background-attachment: fixed; /* Make the background fixed during scroll */
}

.top-banner::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(135deg, #192c3b, #788fa3);
  opacity: 0.92; /* Adjust the opacity for desired transparency */
  z-index: 2;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the container */
  justify-content: center; /* Center content vertically */
  width: 100%;
  height: 100%;
  z-index: 5;
}

.heading-container {
  flex: 0 0 auto;
  color: white;
  z-index: 1;
  padding: 80px;
  width: 100%; /* Fixed width for the text container */
  max-width: 1465px; /* Optional max-width for larger screens */
  text-align: left; /* Left-align the text */
}

.banner-heading {
  font-size: 52px;
  margin-bottom: 30px;
}

.banner-heading1 {
  font-size: 22px;
  margin-bottom: 40px;
  font-weight: 500;
  max-width: 600px;
}

.arrow-container {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
}

.arrow-down {
  animation: bounce 1s infinite;
  color: white; /* or any other color you prefer */
  font-size: 30px; /* Adjust size as needed */
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

/*Who are we section*/

.who-are-we-section {
  padding: 120px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f7f9fc;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);
  border-radius: 15px;
  transition: transform 0.2s;
}

.who-are-we-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1150px;
  gap: 60px;
}

.text-container {
  flex: 1;
  padding-right: 50px;
}

.text-container h2 {
  text-align: center;
  margin-bottom: 40px;
  font-size: 40px;
  font-weight: 700;
  color: #34495e;
  letter-spacing: -0.5px;
  z-index: 6;
  border-bottom: 3px solid #34495e;
}

.text-container p {
  margin-bottom: 30px;
  color: #333;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.7;
}

.section-title {
  margin-bottom: 30px;
  font-size: 26px;
}

.section-content {
  max-width: 800px;
  font-size: 20px;
  line-height: 1.7;
}

.content-description,
.content-team,
.content-culture {
  margin-bottom: 25px;
  font-size: 20px;
}

.image-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.who-are-we-image {
  width: 85%;
  height: auto;
  max-height: 420px;
  object-fit: cover;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.12);
  transition: transform 0.2s, box-shadow 0.2s;
}

.title-container {
  position: relative;
  display: flex;
  align-items: center; /* vertically align items in the center */
}

/*Our values section*/
.values-section {
  padding: 90px 50px;
  background-color: #2c3e50;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffffff;
  font-family: "Helvetica Neue", sans-serif; /* A modern sans-serif font */
}

.values-title {
  font-size: 50px;
  font-weight: 800;
  margin-bottom: 70px;
  text-align: center;
  text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.1);
  letter-spacing: 1.5px;
  border-bottom: 3px solid #34495e; /* A subtle border underline */
  padding-bottom: 10px; /* Padding to give space below the title */
}

.values-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 1300px;
  gap: 80px;
}

.value-item {
  flex: 1;
  max-width: 400px;
  background-color: #3b4c63; /* A shade lighter for contrast */
  padding: 45px;
  box-shadow: 0 8px 26px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  transition: all 0.4s ease; /* smoother transitions */
  border: 2px solid rgba(255, 255, 255, 0.05);
  text-align: center;
  position: relative; /* For positioning potential icons or elements inside */
}

.value-item h3 {
  font-size: 34px;
  margin-bottom: 25px;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  letter-spacing: 1px;
}

.value-item p {
  font-size: 19px;
  line-height: 1.85;
  opacity: 0.92;
}

.value-item:hover {
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.25);
  transform: translateY(-12px);
  border: 2px solid rgba(255, 255, 255, 0.1); /* Darkening the border on hover */
}

/* If you're planning to add icons */
.icon-style {
  position: absolute;
  bottom: 20px; /* Positioning 20px from the bottom */
  left: 50%;
  transform: translateX(-50%); /* Centering horizontally */
  font-size: 30px;
}

.title-container {
  position: relative;
  display: flex;
  align-items: center;
}

.values-title {
  /* Your existing styles for values-title */
  position: relative; /* Needed so z-index works properly */
}

/*What we do*/

.what-we-do-section {
  padding: 70px 40px;
  background-color: #f7f7f7; /* Lighter gray for a softer background */
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Helvetica Neue", Arial, sans-serif;
}

/* Styling for the section title */
.section-title {
  font-size: 42px;
  font-weight: 700;
  margin-bottom: 30px;
  text-align: center;
  letter-spacing: 1.2px;
  color: #2c3e50; /* A more muted color */
  border-bottom: 3px solid #34495e;
}

/* Flex container to align the text and image side-by-side */
.what-we-do-content {
  display: flex;
  gap: 50px;
  max-width: 1200px;
  width: 100%;
  align-items: stretch;
}

/* Styling for the text container */
.text-container {
  flex: 1;
  padding: 30px 40px;
  background-color: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08); /* Softer shadows for a refined look */
  border-radius: 12px; /* Rounded corners for a modern look */
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.text-container p {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 20px;
  color: #333;
  text-align: justify; /* Full justification of text */
}

/* Styling for the image container */
.image-container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  transition: all 0.3s ease;
}

/* Styling for the image */
.what-we-do-image {
  width: 500px;
  height: 400px;
  object-fit: cover;
  border-radius: 12px; /* To match the border-radius of the container */
}

.title-container {
  position: relative;
  display: flex;
  align-items: center;
}

.section-title {
  /* Your existing styles for section-title */
  position: relative; /* Needed so z-index works properly */
}

/*Meet Us section*/

.meetUsSection {
  background-color: #fafafa;
  color: #2a324b;
  padding: 80px 0;
  font-family: "Arial", sans-serif;
  text-align: center;
}

.titleContainer {
  position: relative;
  text-align: center;
}

.titleWrapper {
  position: relative;
  z-index: 1;
}

.meetUsTitle {
  font-size: 46px;
  font-weight: 700;
  margin-bottom: 30px;
  color: #2a324b;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
}

.contentWrapper {
  max-width: 800px;
  margin: 0 auto;
}

.meetUsDescription {
  font-size: 20px;
  line-height: 1.6;
  margin-bottom: 50px;
  color: #6b7280;
}

.meetUsButton {
  padding: 15px 45px;
  font-family: "Montserrat", Arial, sans-serif;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1.2px; /* Increased spacing for readability in uppercase */
  color: #ffffff;
  background-color: #2c3e50; /* Theme color for the button */
  border: none;
  border-radius: 50px; /* Larger border radius for pill-shaped button */
  transition: all 0.3s ease-in-out;
  box-shadow: 0 5px 20px rgba(44, 62, 80, 0.15); /* Box-shadow for a 3D look */
}

.meetUsButton:hover {
  background-color: #1a2433;
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(26, 36, 51, 0.2);
}

.meetUsButton:focus {
  outline: none;
}

.meetUsButton:active {
  transform: translateY(2px);
  box-shadow: 0 3px 10px rgba(26, 36, 51, 0.2);
}

@media screen and (max-width: 810px) {
  /*Top banner*/
  .banner-heading1 {
    font-size: 1em;
    margin-top: 0.5em;
    text-align: left;
    font-weight: lighter;
    max-width: 100%;
  }
  .arrow-container {
    display: none;
  }

  .banner-heading {
    font-size: 34px; /* Adjust font size for smaller screens */
  }

  .top-banner {
    background-color: transparent;
    background-attachment: scroll;
    background-image: contain;
    width: auto;
  }

  /*Who are we*/
  .who-are-we-content {
    flex-direction: column; /* Stack elements vertically */
    align-items: center; /* Center-align elements */
    width: 110%;
  }

  .title-container {
    order: 0; /* Appears first */
    text-align: center; /* Center the title */
  }

  .image-container {
    order: 1; /* Appears second */
    width: 100%; /* Full width */
  }

  .text-container {
    order: 2; /* Appears third */
    width: 100%; /* Full width */
  }

  .who-are-we-image {
    max-width: 100%; /* Make the image full width */
    height: auto;
  }

  /*Our values*/

  .values-title {
    font-size: 34px;
  }

  .values-container {
    width: 120%;
    align-items: center;
    justify-content: center;
  }
  .value-item {
    width: 95%;
    text-align: center;
  }

  /*What we do*/

  .what-we-do-content {
    flex-direction: column; /* stack elements vertically */
    align-items: center; /* center-align elements */
    width: 110%;
  }

  .title-container {
    order: 0; /* appears first */
    text-align: center; /* center the title */
  }

  .image-container {
    order: 1; /* appears second */
    width: 100%; /* full width */
  }

  .text-container {
    order: 2; /* appears third */
    width: 100%; /* full width */
  }

  .what-we-do-image {
    max-width: 100%; /* make the image full width */
    height: auto;
  }

  /*TeamSection*/

  /*Meet Us section*/
  .contentWrapper {
    width: 95%;
  }
}
