/* ContactUs */

.my5 {
  padding-top: 175px;
  padding-bottom: 175px;
  background: linear-gradient(135deg, #243e54, #51616f);
}

.container {
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 15px;
  box-sizing: border-box;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.col {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  box-sizing: border-box;
}

.lg6 {
  flex: 0 0 50%;
  max-width: 50%;
  box-sizing: border-box;
}

.dFlex {
  display: flex;
}

.alignItemsStretch {
  align-items: stretch;
}

.h100 {
  height: 100%;
  border: 2px solid #ffffff;
}

.cardShadow {
  border: 0 !important;
  border-radius: 60px;
  padding: 20px !important;
}

.cardShadow2 {
  border-radius: 15px !important;
  padding: 20px !important;
}

.flexColumn {
  flex-direction: column;
}

.cardBody {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.cardTitle1 {
  font-size: 32px;
  letter-spacing: 1.2px;
  font-family: "Montserrat", Arial, sans-serif;
  color: #ffffff;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  text-align: center;
}

.cardTitle {
  font-size: 32px;
  letter-spacing: 1.2px;
  font-family: "Montserrat", Arial, sans-serif;
  color: #ffffff;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  text-align: center;
}

.cardText1 {
  letter-spacing: 1.2px;
  font-family: "Montserrat", Arial, sans-serif;
  font-size: 16px; /* Increased spacing for readability in uppercase */
  color: #ffffff;
  margin-bottom: 2rem;
  text-align: center;
}

.cardText {
  font-size: 16px;
  letter-spacing: 1.2px;
  font-family: "Montserrat", Arial, sans-serif;
  color: #ffffff;
  margin-bottom: 2rem;
  text-align: center;
}

.mapResponsive {
  width: 100%;
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
}

.mapResponsive iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.formGroup {
  margin-bottom: 1rem;
}

.formControl {
  width: 100%;
  padding: 0.375rem 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #ffffff !important;
  background-color: transparent;
  background-clip: padding-box;
  border: none;
  border-bottom: 2px solid #ffffff;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.formControl:focus {
  border-bottom: 2px solid #ffffff;
  outline: none;
  box-shadow: none;
  background-color: transparent;
}

::placeholder {
  /* For modern browsers */
  color: #ffffff;
  opacity: 1; /* Ensure color is applied correctly */
}

:-ms-input-placeholder {
  /* For Internet Explorer 10-11 */
  color: #ffffff;
}

::-ms-input-placeholder {
  /* For Microsoft Edge */
  color: #ffffff;
}

.submit {
  padding: 15px 45px;
  font-family: "Montserrat", Arial, sans-serif;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1.2px;
  color: white;
  background-color: transparent;
  border: none;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
  border: 1px solid white;
  cursor: pointer;
  margin-top: 2em;
  align-self: center;
  text-align: center; /* Ensure text is centered */
}

.submit:hover {
  background-color: white;
  transform: translateY(-3px);
  color: #122635;
}

.submit:focus {
  outline: none;
}

.submit:active {
  transform: translateY(2px);
  box-shadow: 0 3px 10px rgba(0, 86, 179, 0.4);
}

.serviceItem2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 320px;
  height: 390px;
  padding: 20px;
  background-color: #354453;
  letter-spacing: 1.2px;
  font-family: "Montserrat", Arial, sans-serif;
  border-radius: 8px;
  color: white;
  text-align: center;
  transition: all 0.3s ease;
}

/* Add these to ContactForm.module.css */

.checkboxContainer {
  display: flex;
  align-items: center;
  margin-top: 1em;
  color: #ffffff;
  font-size: 0.8rem;
}

.checkbox {
  margin-right: 0.5rem;
  width: 15px;
  height: 15px;
  border: 2px solid #ffffff;
  background: transparent;
  appearance: none;
  cursor: pointer;
  position: relative;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.checkbox:checked {
  background-color: transparent;
  border-color: white;
}

.checkbox:checked::after {
  content: "";
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
}

.checkbox + label {
  cursor: pointer;
}

.checkbox + label a {
  color: white;
}

.checkbox + label a:hover {
  color: #007bff;
}

.errorMessage {
  color: red;
  margin-top: 0.5rem;
  text-align: center;
}

.popup {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 100px; /* Position near the top of the screen */
  right: 20px;
  min-width: 300px;
  padding: 15px 30px;
  background-color: #ffffff; /* White background */
  border-radius: 4px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.2);
  color: #34495e;
  opacity: 0; /* Start transparent */
  visibility: hidden; /* Start hidden */
  transition: visibility 0s linear 0.5s, opacity 0.5s linear;
  z-index: 1000; /* Ensure it's above other elements */
}

/* Popup Text */
.popupMessage {
  font-size: 1rem;
  margin: 0 10px;
}

/* Close Icon */
.closeIcon {
  font-size: 1.5rem;
  cursor: pointer;
}

/* Fade-in animation */
.fadeIn {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.5s linear;
}

@media screen and (max-width: 810px) {
  /* Contact */
  .my5 {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .lg6 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .mapResponsive {
    height: 150px;
    width: 100%;
  }

  .buttonCustom {
    width: 100%;
  }

  .cardTitle1,
  .cardTitle {
    font-size: 24px;
  }

  .cardText1,
  .cardText {
    font-size: 16px;
  }

  .fa2x {
    font-size: 1.5em;
  }
}
