/*Top banner*/
.top-banner {
  height: 630px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center; /* Center the content horizontally */
  overflow: hidden;
  z-index: 1;
  background-image: url("assets/images/mirrored_bannerimg.webp");
  background-size: cover; /* Cover the entire element */
  background-repeat: no-repeat; /* Do not repeat the image */
  padding-top: 80px; /* Adjusted for the header height */
  background-attachment: fixed; /* Make the background fixed during scroll */
}

.top-banner::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(135deg, #192c3b, #788fa3);
  opacity: 0.92; /* Adjust the opacity for desired transparency */
  z-index: 2;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the container */
  justify-content: center; /* Center content vertically */
  width: 100%;
  height: 100%;
  z-index: 5;
}

.heading-container {
  flex: 0 0 auto;
  color: white;
  z-index: 1;
  padding: 80px;
  width: 100%; /* Fixed width for the text container */
  max-width: 1465px; /* Optional max-width for larger screens */
  text-align: left; /* Left-align the text */
}

.banner-heading {
  font-size: 52px;
  margin-bottom: 30px;
}

.banner-heading1 {
  font-size: 22px;
  margin-bottom: 40px;
  font-weight: 500;
}

.arrow-container {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
}

.arrow-down {
  animation: bounce 1s infinite;
  color: white; /* or any other color you prefer */
  font-size: 30px; /* Adjust size as needed */
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

/*Article CSS*/

.articleTitle {
  font-size: 2em;
  margin-bottom: 0.5em;
  text-align: center;
  color: #192c3b;
  margin-top: 40px;
}

.articleDate {
  color: #192c3b;
  font-style: italic;
  margin-bottom: 1em;
  text-align: center;
  margin: 0; /* Standardizing margin */
}

.articleImage {
  display: block;
  width: calc(
    100% - 160px
  ); /* Ensures the image fills the screen with 80px margin on each side */
  max-width: 1345px; /* Maximum width of the image */
  height: auto; /* Maintain aspect ratio */
  max-height: 400px; /* Optional: Limit the maximum height to keep images consistent */
  object-fit: cover; /* Ensures the image scales properly without distortion */
  border-radius: 8px; /* Rounded corners */
  margin: 30px auto; /* Centers the image horizontally and adds vertical spacing */
}
.articleText {
  line-height: 1.6;
  margin-bottom: 2em;
  padding: 0 1em; /* Adds horizontal padding */
  text-align: justify;
  width: calc(
    100% - 160px
  ); /* Ensures the image fills the screen with 80px margin on each side */
  max-width: 1045px; /* Maximum width of the image */
  height: auto; /* Centers the text block horizontally */
  color: #192c3b;
  /* Centers the text block horizontally */
  margin: 30px auto;
}

.infoContainer {
  display: flex;
  align-items: center; /* Align items vertically */
  justify-content: center; /* Center items horizontally */
  gap: 10px; /* Space between children */
  margin-bottom: 10px; /* Space below the container */
}

.categories {
  display: flex;
  align-items: center;
  justify-content: center; /* Centers items horizontally in the container */
  gap: 10px;
  margin-bottom: 10px;
}

.articleDate {
  margin: 0; /* Removes default margin to better control spacing */
  font-weight: 600;
}

.categoryTag {
  display: inline-block;
  background-color: #33465a;
  color: white;
  padding: 5px 10px;
  margin: 0 5px; /* Adjusted margin for inline flow */
  border-radius: 5px;
  font-size: 0.8em;

  transition: background-color 0.3s;
}

.dateReadContainer {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #666;
}

.publishedDate {
  margin-right: 20px;
}

.readTime {
  display: flex;
  align-items: center;
  margin: 0;
}

.clockIcon {
  margin-right: 5px;
  color: black;
  font-weight: bold;
}

/*Rekomendationerna*/

/* ArticleDetails.module.css */
.relatedArticles {
  margin-top: 40px;
  padding: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  margin-bottom: 40px;
}

.relatedArticles h2 {
  text-align: center;
  margin-bottom: 30px;
  margin-top: 90px;
  color: #192c3b;
  font-size: 30px;
}

.relatedArticlesGrid {
  margin-top: 50px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.relatedArticleCard {
  position: relative; /* Needed to position the categories absolutely within the card */
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: transform 0.3s ease;
  border-radius: 15px;
  background: linear-gradient(135deg, #192c3b, #788fa3);
  height: 450px;
  max-width: 430px;
}

.relatedArticleCard:hover {
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  transform: scale(1.05);
}

.articleImageContainer {
  position: relative; /* Allows positioning of elements inside the container */
}

.relatedArticleImage {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 15px 15px 0 0; /* Rounded corners only at the top */
}

.relatedArticleLink {
  text-decoration: none;
  color: inherit;
}

.relatedArticleLink h3 {
  font-size: 20px;
  font-weight: bold;
  color: white;
  text-decoration: none; /* Remove underline */
  text-decoration: none;
  align-items: center;
  text-align: left;
  padding: 20px;
}

.relatedArticleLink p {
  font-size: 16px;
  color: white;
  margin-bottom: 8px;
  display: block;
  text-decoration: none;
  text-align: center;
  padding: 10px;
  margin-bottom: 10px;
}

.relatedArticleCategories {
  position: absolute; /* Position relative to the .articleImageContainer */
  bottom: 10px; /* Position at the bottom of the image */
  left: 10px; /* Position from the left of the image */
  display: flex;
  gap: 5px;
  padding: 5px 10px;
  /* Semi-transparent background */
  border-radius: 5px;
  font-size: 14px;
  z-index: 10; /* Ensure it stays above the image */
  align-items: center;
}

.relatedCategoryTag,
.relatedArticleDate {
  background: rgba(0, 0, 0, 0.6);
  color: white;
  border-radius: 4px;
  padding: 2px 6px;
}

.relatedArticleDate {
  margin-right: 5px;
}

.learnMore {
  position: absolute; /* Position it within the .articleImageContainer */
  bottom: 10px; /* Position at the bottom left of the image */
  left: 20px; /* Position from the left of the image */
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #ffff; /* White color */
  text-decoration: none;
  opacity: 1; /* Ensure it's visible initially */
  transition: opacity 0.3s ease; /* Smooth transition for opacity */
  z-index: 11; /* Ensure it appears above the image and other elements */
}
.arrow {
  transition: transform 0.4s ease, margin-left 0.4s ease; /* Smooth transition */
}

.arrow {
  margin-left: 5px;
  transition: transform 0.3s ease;
  color: #ffffff;
}

.relatedArticleCard:hover .arrow {
  transform: translateX(10px); /* Move arrow slightly on hover */
  opacity: 1;
}

.relatedArticleCard:hover .learnMore {
  font-weight: bold;
}

.spinner {
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
  margin: 50px auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* or adjust according to your design */
}

/* Mobile and Tablet Adjustments for rekommendationer */

@media (max-width: 1100px) {
  .relatedArticlesGrid {
    grid-template-columns: 1fr; /* 2 columns for tablets */
  }

  .relatedArticleCard {
    width: 100%; /* Example: make width full container width */
    /* Other styles... */
    max-width: 350px;
    margin: 0 auto;
    height: 450px;
  }

  .relatedArticles h2 {
    font-size: 24px;
  }
  .relatedArticleCard h3 {
    font-size: 17px;
  }
}

@media screen and (max-width: 900px) {
  .articleImage {
    width: 95%;
    height: auto;
  }
}

@media screen and (max-width: 810px) {
  /*Top banner*/
  .banner-heading1 {
    font-size: 18px;
    margin-top: 1em;
    text-align: left;
    font-weight: lighter;
    max-width: 100%;
  }
  .arrow-container {
    display: none;
  }

  .banner-heading {
    font-size: 35px; /* Adjust font size for smaller screens */
    margin-top: -50px;
  }

  .top-banner {
    background-color: transparent;
    background-attachment: scroll;
    background-image: contain;
    width: auto;
  }

  /*Article*/

  .articleImage {
    max-height: 200px; /* Smaller fixed max-height for smaller screens */
    padding: 10px;
  }

  .articleText {
    text-align: left; /* Align text left for better readability on small screens */
    padding: 0px;
  }
}
