.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.modalContent {
  background: linear-gradient(135deg, #151f2b, #39627e);
  padding: 40px;
  border-radius: 12px;
  position: relative;
  width: 100%;
  max-width: 500px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  animation: fadeIn 0.5s ease-in-out;
  color: white;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.closeButton {
  position: absolute;
  top: -5px; /* Adjusted for better alignment */
  right: 15px; /* Adjusted for better alignment */
  background: none;
  border: none;
  font-size: 40px; /* Made bigger */
  cursor: pointer;
  color: white;
  transition: color 0.3s ease;
}

.closeButton:hover {
  color: rgb(218, 60, 60); /* Change to red on hover */
}

.combinedModal {
  text-align: center;
}

.cookieSection,
.languageSection {
  transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.cookieSection {
  max-height: 300px;
  overflow: hidden;
}

.cookieSection h1 {
  font-size: 36px;
  font-weight: bold;
}

.languageSection {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
}

.languageSection.visible {
  max-height: 500px;
  opacity: 1;
}

.buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.button {
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  color: white;
  background-color: #26405c;
}

.button:hover {
  background-color: #0056b3;
  transform: translateY(-3px);
}

.button.selected {
  background-color: #234265;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2167b1;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.toggleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0px; /* Adjusted to match the spacing of consent toggles */
  margin: 20px 0;
}

.languageToggleContainer {
  display: flex;
  justify-content: space-between; /* Ensures even spacing between elements */
  align-items: center;
  gap: 0px; /* Adjusted gap between language sliders */
  margin: 20px 0;
  width: 100%; /* Ensures the container takes up full width */
}

.toggleOption {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1; /* Ensures equal space for each toggle option */
}

.checkboxContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.checkboxContainer input {
  margin-left: 10px;
}

.continueButtonContainer {
  margin-top: 30px;
}

.continueButton {
  padding: 10px 20px;
  border: none;
  border-radius: 50px;
  background-color: transparent;
  color: white;
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.1s ease, transform 0.1s ease;
  margin-top: 20px;
  border: 1px solid white;
}

.continueButton:hover {
  background-color: #ffffff;
  transform: translateY(-3px);
  color: black;
}

.activeLabel {
  font-weight: bold;
}
