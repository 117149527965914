.footer {
  background-color: #2c3e50;
  color: white;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footer-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.footer-section {
  flex: 1;
  min-width: 200px;
  margin: 20px;
  text-align: center; /* Center text within footer sections */
}

.footer-section h2,
.footer-section h3 {
  margin-bottom: 15px;
  font-weight: bold;
  text-align: center; /* Center h2 and h3 elements */
}

.footer-section h2 {
  font-size: 2em;
}

.footer-section h3 {
  font-size: 1.2em;
}

.footer-section p,
.footer-section ul {
  margin-bottom: 15px;
}

.footer-section ul {
  list-style: none; /* Remove default bullets */
  padding: 0;
  margin-left: 0px;
}

.footer-section ul li {
  margin-bottom: 10px;
}

/* Apply underline effect instead of changing text color on hover */
.footer-section ul li a,
.footer-section p a,
.footer-bottom ul li a,
.social-icons a {
  position: relative;
  color: white; /* Keep the link color white */
  text-decoration: none; /* Remove underline */
  padding: 5px 0;
  transition: color 0.3s;
}

.footer-section ul li a::after,
.footer-section p a::after,
.footer-bottom ul li a::after,
.social-icons a::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  height: 2px;
  width: 0;
  background-color: var(--secondaryColor); /* Or any color you prefer */
  transition: width 0.4s ease, left 0.4s ease;
}

.footer-section ul li a:hover::after,
.footer-section p a:hover::after,
.footer-bottom ul li a:hover::after,
.social-icons a:hover::after {
  width: 100%;
  left: 0;
}

/* Existing Styles */
.footer-section p {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
}

.social-icons {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.footer-bottom {
  text-align: center;
  border-top: 2px solid #394f66;
  padding: 20px 0;
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
}

.footer-bottom ul {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 10px;
}

.footer-bottom ul li {
  display: inline;
}

.footer-bottom p {
  font-size: 0.8em;
}

a {
  color: white;
  text-decoration: none;
}

@media screen and (max-width: 600px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .social-icons {
    justify-content: center;
  }

  .footer-section {
    margin-bottom: 20px;
    padding: 0 10px;
  }

  .footer-bottom ul {
    flex-direction: column;
    gap: 10px;
  }

  .footer-bottom ul li {
    margin-bottom: 10px;
  }
}
