/*Top banner*/
.top-banner {
  height: 630px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center; /* Center the content horizontally */
  overflow: hidden;
  z-index: 1;
  background-image: url("assets/images/mirrored_bannerimg.webp");
  background-size: cover; /* Cover the entire element */
  background-repeat: no-repeat; /* Do not repeat the image */
  padding-top: 80px; /* Adjusted for the header height */
  background-attachment: fixed; /* Make the background fixed during scroll */
}

.top-banner::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(135deg, #192c3b, #788fa3);
  opacity: 0.92; /* Adjust the opacity for desired transparency */
  z-index: 2;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the container */
  justify-content: center; /* Center content vertically */
  width: 100%;
  height: 100%;
  z-index: 5;
}

.heading-container {
  flex: 0 0 auto;
  color: white;
  z-index: 1;
  padding: 80px;
  width: 100%; /* Fixed width for the text container */
  max-width: 1465px; /* Optional max-width for larger screens */
  text-align: left; /* Left-align the text */
}

.banner-heading {
  font-size: 52px;
  margin-bottom: 30px;
}

.banner-heading1 {
  font-size: 22px;
  margin-bottom: 40px;
  font-weight: 500;
}

.arrow-container {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
}

.arrow-down {
  animation: bounce 1s infinite;
  color: white; /* or any other color you prefer */
  font-size: 30px; /* Adjust size as needed */
}

.arrow {
  transition: transform 0.4s ease, margin-left 0.4s ease; /* Smooth transition */
}

.articlePreview:hover .arrow {
  transform: translateX(7px); /* Move arrow slightly on hover */
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

/* Article container */
.articlesContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Four articles per row */
  gap: 20px; /* Space between articles */
  max-width: 1480px; /* Maximum width for the articles container */
  padding: 80px;
  margin: 0 auto;
}

.articleLink {
  text-decoration: none;
  color: inherit; /* This prevents the link from applying its color to child elements */
}

.articlePreview {
  border: 1px solid #ddd;
  border-radius: 8px;
  background: linear-gradient(135deg, #192c3b, #788fa3);
  text-align: left;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  overflow: hidden; /* Hide overflow */
  position: relative; /* Needed for absolutely positioning the learn more button */
  height: 400px;
}

.articlePreview:hover {
  transform: scale(1.05);
}

.articleImageContainer {
  position: relative;
}

.articleImage {
  width: 100%;
  height: 200px; /* Fixed height for the image */
  object-fit: cover;
  border-radius: 8px 8px 0 0;
}

.articleDateCategory {
  position: absolute;
  bottom: 10px;
  left: 10px;
  display: flex;
  gap: 5px;
}

.articleDate,
.categoryTag {
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 4px;
  padding: 2px 6px;
  font-size: 0.8em;
}

.articleTextContent {
  flex: 1;
  padding: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden; /* Hide overflow */
}

.articleTitle {
  font-size: 1em;
  margin-bottom: 10px;
  color: white;
  font-weight: bold;
}

.articleSummary {
  font-size: 0.9em;
  color: white;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* Limit to 4 lines */
  -webkit-box-orient: vertical;
  margin-bottom: -5px; /* Ensure space for the learn more button */
}

.learnMore {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #000; /* Black color, adjust if necessary */
  text-decoration: none;
  opacity: 1; /* Ensure it's visible initially */
  transition: opacity 0.3s ease; /* Smooth transition for opacity */
  color: #ffff;
  margin-left: 15px;
  margin-bottom: 10px;
}

@media (max-width: 1264px) {
  .articlesContainer {
    grid-template-columns: repeat(
      3,
      1fr
    ); /* Three articles per row on smaller screens */
  }
}

@media (max-width: 1028px) {
  .articlesContainer {
    grid-template-columns: repeat(
      2,
      1fr
    ); /* Two articles per row on smaller screens */
  }
  .searchInput {
    width: 90%;
  }
}

@media (max-width: 800px) {
  .articlesContainer {
    grid-template-columns: 1fr; /* One article per row on very small screens */
  }
  .searchInput {
    width: 100%;
  }
}

/* Search and Sort container */
.searchAndSortContainer {
  max-width: 1480px; /* Maximum width for the container */
  margin: 0 auto; /* Center the container */
  padding-left: 80px; /* Padding around the container */
  margin-top: 20px;
}

.searchAndSortContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align search and sort to the left */
  gap: 20px; /* Space between search and sort sections */
  width: 100%;
  max-width: 1265px; /* Optional max-width for larger screens */
}

.searchContainer {
  display: flex;
  color: #007bff;
  margin-top: 30px;
}

.searchInput {
  width: 100%;
  width: 275px;
  padding: 10px 15px;
  font-size: 16px;
  border: 2px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s;
  color: #333;
}

.searchInput::placeholder {
  color: #ccc;
}

/* Sorting button */
.sortDropdownWrapper {
  display: flex;
  gap: 10px; /* Space between dropdowns */
}

.sortDropdownContainer {
  position: relative;
  display: inline-block;
}

.sortDropdownContainer::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 10px;
  width: 15px; /* Adjust width */
  height: 15px; /* Adjust height */
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M12 15l-8-8h16z' fill='black'/%3E%3C/svg%3E")
    no-repeat center;
  background-size: 15px 15px; /* Adjust size */
  transform: translateY(-50%);
  pointer-events: none; /* Prevent arrow from being clicked */
}

.sortDropdown {
  -webkit-appearance: none; /* Remove default styling for Safari */
  -moz-appearance: none; /* Remove default styling for Firefox */
  appearance: none; /* Remove default styling for other browsers */
  background: #f7f7f7; /* Background color */
  border: 1px solid #ccc; /* Border color */
  padding: 10px 30px 10px 10px; /* Padding inside the select box */
  font-size: 16px; /* Font size */
  border-radius: 4px; /* Rounded corners */
  color: #333; /* Text color */
  transition: border-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for border and shadow */
}

.sortDropdown:focus {
  outline: none; /* Remove default outline */
  border-color: #007bff; /* Border color on focus */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Box shadow on focus */
}

.arrow {
  margin-left: 5px;
  transition: transform 0.3s ease;
  color: #ffffff;
}

.articlePreview:hover .arrow {
  transform: translateX(10px); /* Move arrow slightly on hover */
  opacity: 1;
}

.articlePreview:hover .learnMore {
  font-weight: bold;
}

/* Pagination Styling */
.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 10px 0;
  font-family: Arial, sans-serif;
}

.pageItem {
  margin: 0 5px;
}

.pageLink {
  color: #666;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 5px 10px;
  border-radius: 4px;
  text-decoration: none;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s, border-color 0.2s;
}

.pageLink:hover,
.pageLink:focus {
  background-color: #eee;
  border-color: #ccc;
}

.pageLink.active {
  color: #fff;
  background-color: #666;
  border-color: #666;
}

.pageLink.active {
  color: #fff;
  background-color: #33465a;
  border-color: #33465a;
}

.pageLink {
  color: #333;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  padding: 8px 12px;
  border-radius: 4px;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s, border-color 0.2s;
}

.pageLink:hover,
.pageLink:focus {
  background-color: #e9ecef;
  color: #33465a;
}

@media screen and (max-width: 810px) {
  /*Top banner*/
  .banner-heading1 {
    font-size: 18px;
    margin-top: 1em;
    text-align: left;
    font-weight: lighter;
    max-width: 100%;
  }
  .arrow-container {
    display: none;
  }

  .banner-heading {
    font-size: 35px;
    margin-top: -50px;
  }

  .top-banner {
    background-color: transparent;
    background-attachment: scroll;
    background-image: contain;
    width: auto;
  }

  .articlePreview:hover {
    transform: none; /* Disable hover scale effect on mobile */
  }
}
