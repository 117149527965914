/*Top bannern*/
.top-banner {
  height: 630px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  z-index: 1;
  background-image: url("assets/images/mirrored_bannerimg.png");
  background-size: cover; /* Cover the entire element */
  background-repeat: no-repeat; /* Do not repeat the image */
  padding-top: 80px; /* Adjusted for the header height */
  background-attachment: fixed; /* Make the background fixed during scroll */
}

.top-banner::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(135deg, #192c3b, #788fa3);
  opacity: 0.92; /* Adjust the opacity for desired transparency */
  z-index: 2;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  z-index: 5;
}

.heading-container {
  flex: 0 0 auto;
  text-align: left;
  color: white;
  z-index: 1;

  padding: 0 20px;
  margin-top: 50px;
}

.banner-heading {
  font-size: 52px;
  margin-bottom: 30px;
  margin-left: 17px;
}

.banner-heading1 {
  font-size: 22px;
  margin-bottom: 40px;
  font-weight: 500;
  max-width: 40%;
  margin-left: 17px;
}

.arrow-container {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
}

.arrow-down {
  animation: bounce 1s infinite;
  color: white; /* or any other color you prefer */
  font-size: 40px; /* Adjust size as needed */
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

@media screen and (max-width: 810px) {
  /*Top banner*/
  .banner-heading1 {
    font-size: 18px;
    margin-top: 1em;
    text-align: left;
    font-weight: lighter;
    max-width: 100%;
  }
  .arrow-container {
    display: none;
  }

  .banner-heading {
    font-size: 35px; /* Adjust font size for smaller screens */
    margin-top: -50px;
  }
}
