@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");

.fadeIn {
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-fill-mode: both;
}
/* Bannern */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Applying animation to text elements */
.customtitle,
.customtitle2,
.customtitle4 {
  animation-name: fadeIn;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  word-wrap: normal;
  overflow-wrap: normal;
  white-space: normal;
}

.customtitle {
  letter-spacing: -1.5px;

  font-size: 62px;
  color: #f7f7f7;
  font-weight: 600;
  text-shadow: 0 0 8px rgba(255, 255, 255, 0.1);
  overflow: hidden;
  opacity: 0; /* Initial state */
}

.customtitle2 {
  letter-spacing: -1.5px;
  font-weight: 400;
  font-size: 62px;
  color: #f7f7f7;
  margin-bottom: 60px;
  text-decoration-color: #ffffff;
  text-decoration-thickness: 5px;
  text-underline-offset: 7px;
  text-shadow: 0 0 8px rgba(255, 255, 255, 0.1);
  opacity: 0; /* Initial state */
}

.bannerButtonStyle {
  background: linear-gradient(45deg, #192c3b, #556a7c);
  color: #f7f7f7; /* Text color */
  padding: 15px 30px; /* Padding inside the button */
  font-weight: 700; /* Makes the text bold */
  /* Transforms the text to uppercase */
  cursor: pointer; /* Changes the cursor to pointer on hover */
  transition: background-color 0.3s; /* Smooth transition for hover effect */
  border-radius: 50px;
  box-shadow: 0 5px 20px rgba(44, 62, 80, 0.15);
  text-decoration: none;
  font-size: 16px;
}

.bannerButtonStyle .projectReadMoreArrow {
  margin-left: 3px;
}

.bannerButtonStyle:hover {
  background: linear-gradient(45deg, #192c3b, #556a7c);
  color: #f7f7f7;
}

.bannerButtonStyle:hover .arrowPath {
  d: path("M12 2l-1.41 1.41L16.17 9H2v2h14.17l-5.58 5.59L12 18l8-8-8-8z");
  transform: translateX(3px);
  transition: transform 0.1s ease;
}

.bannerButton2Style {
  background-color: transparent; /* Adjust color to match your design */
  border: 0;
  color: white; /* Text color */
  padding: 10px 20px; /* Padding inside the button */
  font-weight: 700; /* Makes the text bold */
  /* Transforms the text to uppercase */
  cursor: pointer; /* Changes the cursor to pointer on hover */
  transition: background-color 0.3s; /* Smooth transition for hover effect */
  border-radius: 15px;
  text-decoration: none;
  font-size: 16px;
}

.bannerButton2Style:hover {
  color: #f7f7f7;
}

.bannerButton2Style .projectReadMoreArrow {
  margin-left: 3px;
}

.bannerButton2Style:hover .arrowPath {
  d: path("M12 2l-1.41 1.41L16.17 9H2v2h14.17l-5.58 5.59L12 18l8-8-8-8z");
  transform: translateX(3px);
  transition: transform 0.1s ease;
}

.checkboxWrapper2 {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-left: -40px;
  margin-top: -50px;
}

/* Applying animation to checkbox icons */
.checkmarkIcon,
.checkmarkIcon2,
.checkmarkIcon3 {
  color: white;
  width: 35px !important;
  height: auto !important;
  overflow: hidden;
  margin-top: -18px;
  opacity: 0; /* Initial state */
  animation-name: fadeIn;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

.serviceIconWrapper {
  margin-top: -20px;
  margin-bottom: -75px;
}
.serviceIcon2 {
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-bottom: 55px;
  position: relative;
  overflow: hidden;
  z-index: -1;
  margin-left: -20px;
  background-color: transparent;
}
.startBanner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 940px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  background-image: url("assets/images/bannerimg.webp");
  background-size: cover; /* Cover the entire element */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-attachment: fixed; /* Make the background fixed during scroll */
}

.startBanner::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(135deg, #192c3b, #788fa3);
  opacity: 0.92; /* Adjust the opacity for desired transparency */
  z-index: 2;
}

.huvudKontainer {
  width: 100%;

  align-items: center;
  text-align: center;
  z-index: 100;
  margin-bottom: 20px;
}

.arrow-container {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
}

.arrow-down {
  animation: bounce 1s infinite;
  color: white; /* or any other color you prefer */
  font-size: 30px; /* Adjust size as needed */
  z-index: 6;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

.bannerImage {
  width: 60%;

  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100%;
  position: relative;
  z-index: 2;
  clip-path: polygon(40% 0%, 100% 0%, 100% 100%, 10% 100%);
}

/*ServicesSectionen*/

.block {
  margin: 0px;
  max-width: 80%;
  margin: 40px auto;
  padding: 40px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 99;
}
.servicesSection {
  background-color: #f7f7f7;
  padding-top: 1px;
  padding-bottom: 60px;
}
.servicesTitle {
  text-align: center;
  font-size: 40px;
  color: #34495e;
  margin-top: 90px;
  font-weight: bold;
}
.servicesDescriptionText {
  text-align: center;
  font-size: 18px;
  letter-spacing: 1.2px;
  font-family: "Montserrat", Arial, sans-serif;
  color: #33465a;
  margin-bottom: 20px;
}

/* Grid Layout */
.servicesGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  padding: 70px 0;
  margin: 0 auto;
  max-width: 1000px;
  justify-items: center;
}

/* Service Item Base Styles */
.serviceLink {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* You can set this to whatever you need */
  width: 100%; /* You can set this to whatever you need */
  text-decoration: none;
}
.serviceItem {
  padding: 20px;
  background: linear-gradient(45deg, #192c3b, #556a7c);
  border-radius: 15px;
  transition: all 0.3s ease, background 0.3s ease;
  height: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  overflow: hidden;
}

.serviceItem:hover {
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  transform: scale(1.05);
}

.serviceIcon {
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-bottom: 40px;
  position: relative;
  overflow: hidden;
}
.serviceTitle {
  font-size: 24px;
  letter-spacing: 1.2px;
  font-family: "Montserrat", Arial, sans-serif;
  margin-bottom: 10px;
  text-align: left;
}
.serviceDescription {
  font-size: 16px;
  color: white;
  text-align: center;
}

/* Individual Service Item Customizations */
/* Service 1 and 2 */
.servicesGrid > a:nth-child(1) .serviceItem,
.servicesGrid > a:nth-child(2) .serviceItem {
  background-size: cover;
  background-position: center;
  width: 400px;
}

.servicesGrid > a:nth-child(1).serviceLink {
  max-width: 400px;
  margin-right: -87px;
}
.servicesGrid > a:nth-child(2).serviceLink {
  max-width: 400px;
  margin-left: -87px;
}

.servicesGrid > a:nth-child(1) .serviceImage,
.servicesGrid > a:nth-child(2) .serviceImage {
  width: 95%;
  height: 190px;
  align-self: center;
  border-radius: 15px;
  filter: brightness(100%);
  margin-top: 20px;
  object-fit: cover;
}

/* Service 3 */
.servicesGrid > a:nth-child(3) {
  grid-column: 1 / 3;
  width: 100%;
}
.servicesGrid > a:nth-child(3) .serviceItem {
  background-size: cover;
  background-position: center;
  padding-top: 22%;
  width: 820px;
  height: 300px;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.servicesGrid > a:nth-child(3) .leftContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 45%;
}
.servicesGrid > a:nth-child(3) .serviceTitle {
  margin-top: -320px;
  margin-bottom: 10px;
}
.servicesGrid > a:nth-child(3) .serviceImage {
  width: 52%;
  height: 260px;
  align-self: center;
  border-radius: 15px;
  filter: brightness(100%);
  margin-top: -160px;
  object-fit: cover;
}

.servicesGrid > a:nth-child(3).serviceLink {
  max-width: 820px;
}

/* Service Icon Styles */
.serviceIcon3 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.serviceIcon3::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: rgba(173, 216, 230, 0.3);
  z-index: -1;
  opacity: 0;
  transform: scale(0.5);
  animation: radialPulse 8s infinite;
}

.serviceIcon3 > svg {
  /* Icon styling for SVG elements */
  width: 110px;
  height: 110px;
  fill: white;
}

/* Animation for radial pulse effect */
@keyframes radialPulse {
  0%,
  100% {
    opacity: 0;
    transform: scale(0.5);
  }
  25% {
    opacity: 0.6;
    transform: scale(1);
  }
  50%,
  75% {
    opacity: 0;
    transform: scale(1.5);
  }
}

/* Adjusting animation delays for service items */
.servicesGrid2 > .serviceItem2:nth-child(1) .serviceIcon3::before {
  animation-delay: 0s;
}
.servicesGrid2 > .serviceItem2:nth-child(2) .serviceIcon3::before {
  animation-delay: 2s;
}
.servicesGrid2 > .serviceItem2:nth-child(3) .serviceIcon3::before {
  animation-delay: 4s;
}
.servicesGrid2 > .serviceItem2:nth-child(4) .serviceIcon3::before {
  animation-delay: 6s;
}

/* Adjusting positions for specific service items */
.servicesGrid2 > .serviceItem2:nth-child(2),
.servicesGrid2 > .serviceItem2:nth-child(4) {
  margin-top: -60px;
}

.serviceItem2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 320px;
  height: 390px;
  padding: 20px;
  background-color: #354453;
  letter-spacing: 1.2px;
  font-family: "Montserrat", Arial, sans-serif;
  border-radius: 8px;
  color: white;
  text-align: center;
  transition: all 0.3s ease;
}

/*Services section 2*/
.servicesSection2 {
  width: 575px;
  background-color: transparent;
  margin-bottom: -60px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  z-index: 10;
  perspective: 1000px;
}

.servicesTitle2 {
  color: #2c3e50;
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 1.2px;
  font-family: "Montserrat", Arial, sans-serif;
  text-align: center;
  text-shadow: 0 0 8px rgba(255, 255, 255, 0.1);
}

.titleContainer {
  width: 100%;
  height: 150px;
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.05);
}

.servicesDescriptionText2 {
  color: #2c3e50;
  letter-spacing: 1.2px;
  font-family: "Montserrat", Arial, sans-serif;
  text-align: center;
  margin-top: 10px;
  font-size: 18px;
}

.letter {
  font-family: "Montserrat", Arial, sans-serif;
}

/* Projects.module.css */
.projectsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 75px;
  background-color: #f7f7f7;
  width: 100%;
  z-index: 0;
  position: relative;
}

.project {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.projectNormal {
  flex-direction: row-reverse;
}

.projectReversed {
  flex-direction: row;
}

.projectTextContainer,
.projectImageContainer {
  flex: 1;
  width: 50%;
  height: 700px;
  position: relative;
}

.project .projectTextContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 0px;
  background-color: #ffffff !important;
  box-sizing: border-box;
  z-index: 1;
}

.project .projectImageContainer {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: transform 0.2s ease;
  overflow: hidden;
  z-index: 0;
}

.project .projectImageContainer:hover {
  transform: scale(1.025);
}

.project .projectImageContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform-origin: center;
  transition: transform 0.3s ease;
}

.projectTitle {
  font-size: 32px;
  padding-left: 60px;
  letter-spacing: 1.2px;
  font-family: "Montserrat", Arial, sans-serif;
}

.projectDescription {
  padding-left: 60px;
  padding-top: 10px;
  font-size: 16px;
  max-width: 80%;
  letter-spacing: 1.2px;
  font-family: "Montserrat", Arial, sans-serif;
}

.descriptionLine {
  margin-bottom: 10px;
}

.projectCategory {
  padding-left: 60px;
  font-size: 16px;
  font-weight: bold;
  color: #246288;
  margin-bottom: 10px;
}

.projectReadMoreWrapper {
  padding-left: 50px; /* Adjust margin to align the button */
}

.projectReadMore {
  display: inline-flex; /* Make the button width fit its content */
  align-items: center;
  padding: 5px 10px; /* Add some padding for better appearance */
  text-decoration: none; /* Remove underline from the link */
  font-size: 16px;
  font-weight: 600;
  color: black;
  letter-spacing: 1.2px;
  font-family: "Montserrat", Arial, sans-serif;
  background-color: transparent; /* Ensure background color does not affect width */
}

.projectReadMore .projectReadMoreArrow {
  margin-left: 5px;
  transition: transform 0.3s ease;
}

.projectReadMore:hover .projectReadMoreArrow {
  transform: translateX(10px);
}

/* ContactUs */

.my5 {
  padding-top: 175px;
  padding-bottom: 175px;
  background: linear-gradient(135deg, #243e54, #51616f);
}

.container {
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 15px;
  box-sizing: border-box;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.col {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  box-sizing: border-box;
}

.lg6 {
  flex: 0 0 50%;
  max-width: 50%;
  box-sizing: border-box;
}

.dFlex {
  display: flex;
}

.alignItemsStretch {
  align-items: stretch;
}

.h100 {
  height: auto;
  border: 2px solid #ffffff;
}

.cardShadow {
  border: 0 !important;
  border-radius: 60px;
  padding: 20px !important;
}

.cardShadow2 {
  border-radius: 15px !important;
  padding: 20px !important;
}

.flexColumn {
  flex-direction: column;
}

.mtAuto {
  margin-top: auto;
}

.cardBody {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.cardTitle1 {
  font-size: 32px;
  letter-spacing: 1.2px;
  font-family: "Montserrat", Arial, sans-serif;
  color: #ffffff;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  text-align: center;
}

.cardTitle {
  font-size: 32px;
  letter-spacing: 1.2px;
  font-family: "Montserrat", Arial, sans-serif;
  color: #ffffff;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  text-align: center;
}

.cardText1 {
  letter-spacing: 1.2px;
  font-family: "Montserrat", Arial, sans-serif;
  font-size: 16px; /* Increased spacing for readability in uppercase */
  color: #ffffff;
  margin-bottom: 2rem;
  text-align: center;
}

.cardText {
  font-size: 16px;
  letter-spacing: 1.2px;
  font-family: "Montserrat", Arial, sans-serif;
  color: #ffffff;
  margin-bottom: 2rem;
  text-align: center;
}

.mapResponsive {
  width: 100%;
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
}

.mapResponsive iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.textCenter {
  text-align: center;
}

.me3 {
  margin-right: 1rem;
}

.mb3 {
  margin-bottom: 1rem;
}

.formGroup {
  margin-bottom: 1rem;
}

.formControl {
  width: 100%;
  padding: 0.375rem 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #ffffff !important;
  background-color: transparent;
  background-clip: padding-box;
  border: none;
  border-bottom: 2px solid #ffffff;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.formControl:focus {
  border-bottom: 2px solid #ffffff;
  outline: none;
  box-shadow: none;
  background-color: transparent;
}

::placeholder {
  /* För moderna webbläsare */
  color: #ffffff;
  opacity: 1; /* För att säkerställa att färgen appliceras korrekt */
}

:-ms-input-placeholder {
  /* För Internet Explorer 10-11 */
  color: #ffffff;
}

::-ms-input-placeholder {
  /* För Microsoft Edge */
  color: #ffffff;
}

.buttonCustom {
  display: block;
  margin: 40px auto 0 auto;
  border-radius: 50px;
  border: 2px solid #ffffff;
  text-align: center;
  width: 40%;
}

.buttonCustom:hover {
  background-color: #ffffff;
  color: black;
}

.submit {
  padding: 15px 45px;
  font-family: "Montserrat", Arial, sans-serif;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1.2px;
  color: white;
  background-color: transparent;
  border: none;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
  border: 1px solid white;
  cursor: pointer;
  margin-top: 2em;
  align-self: center;
  text-align: center; /* Ensure text is centered */
}

.submit:hover {
  background-color: white;
  transform: translateY(-3px);
  color: #122635;
}

.submit:focus {
  outline: none;
}

.submit:active {
  transform: translateY(2px);
  box-shadow: 0 3px 10px rgba(0, 86, 179, 0.4);
}

.serviceItem2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 320px;
  height: 390px;
  padding: 20px;
  background-color: #354453;
  letter-spacing: 1.2px;
  font-family: "Montserrat", Arial, sans-serif;
  border-radius: 8px;
  color: white;
  text-align: center;
  transition: all 0.3s ease;
}

/*Article posts*/

.latestPostsSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  background-color: #f7f7f7;
}

.latestPostsTitle {
  margin-top: 90px;
  font-size: 40px;
  margin-bottom: 30px;
  color: #34495e;
  text-decoration: none;
}

.postsContainer {
  margin-top: 50px;
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two posts per row */
  gap: 40px;
  justify-content: center;
  align-items: center;
  margin-bottom: 155px;
}

.postCard {
  position: relative; /* Ensure positioning context for absolute children */
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-decoration: none;
  transition: transform 0.3s ease;
  background: linear-gradient(105deg, #122635, #617b90);
  max-height: 500px;
  border-radius: 15px;
}

.postCard:hover {
  background: linear-gradient(115deg, #122635, #4e7a9f);
}

.postImage {
  width: 400px;
  height: 450px;
  object-fit: cover;
}

.postInfo {
  max-width: 400px;

  text-decoration: none;
  transition: transform 0.3s ease; /* Add transition for smooth effect */
  padding-bottom: none;
  margin-bottom: -20px;
}

.postDate {
  font-size: 16px;
  color: white;
  margin-bottom: 8px;
  display: block;
  margin-left: 16px;
  text-decoration: none;
}

.articleLink,
.articleLink:visited,
.articleLink:hover,
.articleLink:active {
  text-decoration: none;
}

.articleLink .postTitle {
  color: white; /* Or any color you prefer */
  font-size: 20px;
  font-weight: bold;
  /* Other styles for .postTitle */
}

.postTitle {
  margin-left: 16px;
  margin-top: 10px;
}

.pageText {
  color: white; /* Replace with your desired color */
  /* Additional styles if needed */
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease; /* Smooth transition for height */
  margin-left: 16px;
}

.postCard:hover .pageText {
  max-height: 70px; /* Adjust this value as needed to fit the excerpt */
}

.postCategory {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: rgba(0, 0, 0, 0.5); /* Smokey transparent background */
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  z-index: 10;
}

.arrow {
  margin-left: 5px;
  transition: transform 0.3s ease;
  color: #ffffff;
}

.learnMore {
  display: flex;
  align-items: center;

  font-size: 18px;
  color: #000; /* Black color, adjust if necessary */
  text-decoration: none;
  opacity: 1; /* Ensure it's visible initially */
  transition: opacity 0.3s ease; /* Smooth transition for opacity */
  color: #ffff;
  margin-left: 15px;
  margin-bottom: 10px;
  padding-top: 10px;
}

.postCard:hover .arrow {
  transform: translateX(10px); /* Move arrow slightly on hover */
  opacity: 1;
}

.postCard:hover .learnMore {
  font-weight: bold;
}

/*Growth section (innan footern)*/
.growthSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #fafafa;
}

.textContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5rem;
  width: 70%;
  border-radius: 20px; /* More rounded corners for a modern look */

  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transitions */
}

.growthTitle {
  font-size: 42px;
  color: #2c3e50;
  margin-top: 0;
  margin-bottom: 1rem; /* Reduced bottom margin for tighter spacing */
  font-weight: 800;
  line-height: 1.2;
}

.growthDescription {
  font-size: 16px;
  color: #555555;
  margin: 0 auto 2rem; /* Adjusted bottom margin for better spacing */
  max-width: 800px;
  line-height: 1.7;
  letter-spacing: 1.2px;
  font-family: "Montserrat", Arial, sans-serif;
}

.growthButton {
  padding: 15px 45px;
  font-family: "Montserrat", Arial, sans-serif;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1.2px;
  color: #ffffff;
  background-color: #2c3e50;
  border: none;
  border-radius: 50px;
  transition: all 0.3s ease;
  box-shadow: 0 5px 20px rgba(44, 62, 80, 0.15);
  text-decoration: none;
}

.growthButton:hover {
  background-color: #1a2433;
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(26, 36, 51, 0.2);
  color: #ffffff;
}

.growthButton:focus {
  outline: none;
}

.growthButton:active {
  transform: translateY(2px);
  box-shadow: 0 3px 10px rgba(26, 36, 51, 0.2);
}

@media (max-width: 1000px) {
  .servicesGrid {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 10px;
  }

  .huvudKontainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    box-sizing: border-box;
    margin: 0 auto; /* Center the container */
  }

  .customtitle,
  .customtitle2 {
    font-size: 34px; /* Adjust font-size as needed */
    word-break: keep-all; /* Prevent breaking words */
    white-space: normal; /* Ensure normal text wrapping behavior */
    overflow-wrap: break-word; /* Break at word boundaries */
  }

  .bannerImage {
    filter: brightness(40%);
    background-attachment: scroll;
    background-image: cover;
    background-size: contain;
    background-position: center;
    height: auto;
  }

  .bannerImage::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    clip-path: none;
    z-index: 10;
    filter: brightness(100%);
  }

  .startBanner {
    background-color: transparent;
    background-attachment: scroll;
    background-image: contain;
    height: 800px;
    width: auto;
  }

  .checkboxWrapper2 {
    margin-left: -40px; /* Removed negative margin */
    margin-top: 10px;
  }

  .customtitle4 {
    font-size: 0.9em; /* Adjust font-size as needed */
    word-break: keep-all; /* Prevent breaking words */
    white-space: normal; /* Ensure normal text wrapping behavior */
    overflow-wrap: break-word;
  }

  .customtitle3 {
    font-size: 1.5em;
    margin-left: 0; /* Removed negative margin */
    margin-bottom: -10px;
  }

  .checkboxWrapper2 {
    margin-bottom: -40px;
  }

  .serviceItem {
    height: auto;
  }

  .newsSlideshow {
    display: none;
  }

  .textContainer {
    width: 95%;
    order: 1;
  }

  .growthTitle {
    font-size: 28px;
    text-align: left;
    margin: 0 auto;
    text-align: center;
  }

  .growthSection {
    flex-direction: row;
  }

  .growthButton {
    margin: 0 auto;
    margin-top: 40px;
  }

  .growthDescription {
    margin: 0 auto;
    margin-top: 10px;
    width: 100%;
    text-align: center;
    font-size: 14px;
  }

  .serviceItem2 {
    height: 350px;
  }

  .servicesGrid > .serviceLink:nth-child(1) .serviceItem,
  .servicesGrid > .serviceLink:nth-child(2) .serviceItem,
  .servicesGrid > .serviceLink:nth-child(3) .serviceItem {
    width: 95%;
    margin-left: 0px;
    height: 450px;
  }

  .servicesGrid > .serviceLink:nth-child(3) .serviceItem {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }

  .serviceIcon {
    display: none;
  }

  .servicesGrid > .serviceLink:nth-child(3) .serviceTitle {
    order: 1;
    margin-top: -60px;
  }

  .servicesGrid > .serviceLink:nth-child(3) .serviceDescription {
    order: 1;
    max-width: 950px;
    width: 300px;
    text-align: center;
  }

  .servicesGrid > .serviceLink:nth-child(3) .serviceImage {
    order: 1;
    width: 95%;
    height: 200px;
    margin-top: 10px;
  }

  .title-container {
    width: 90%;
    margin-left: 0; /* Removed negative margin */
  }

  .serviceDescription {
    text-align: center;
  }

  .servicesGrid > a:nth-child(1).serviceLink,
  .servicesGrid > a:nth-child(2).serviceLink,
  .servicesGrid > a:nth-child(3).serviceLink {
    max-width: 600px;
    margin-right: 0px;
    margin-left: 0px;
  }

  .servicesGrid > a:nth-child(3) .serviceItem {
    padding-top: 18%;
  }

  .postsContainer {
    grid-template-columns: 1fr; /* One post per row on smaller screens */
  }

  .postCard {
    /* Adjust width and height as needed for smaller screens */
    width: 100%; /* Example: make width full container width */
    /* Other styles... */
    max-width: 350px;
    margin: 0 auto;
  }

  .postImage {
    /* Adjust image size for smaller screens */
    width: 100%; /* Full width of the .postCard */
    height: auto; /* Maintain aspect ratio */
    /* Other styles... */
  }

  .postTitle,
  .postDate,
  .postInfo p {
    /* Adjust font size for smaller screens */
    font-size: smaller; /* Or any specific size */
    /* Other styles... */
  }

  .servicesSection2 {
    width: 100%;
    margin-bottom: 0px;
  }

  .servicesTitle2 {
    font-size: 28px;
    padding-top: 30px;
  }

  .servicesDescriptionText2 {
    font-size: 14px;
  }

  .titleContainer {
    height: auto;
    padding: 1rem;
    box-shadow: none;
    border-radius: 0;
    background-color: #ffffff;
    padding-bottom: 10px;
  }

  .projectsContainer {
    padding-top: 50px;
  }

  .project {
    flex-direction: column;
  }

  .projectTextContainer,
  .projectImageContainer {
    width: 100%;
    height: auto;
  }

  .projectTitle,
  .projectDescription,
  .projectReadMoreWrapper {
    padding-left: 20px;
    padding-right: 20px;
  }

  .projectCategory {
    margin-bottom: 20px;
    margin-top: 30px;
    margin-left: -35px;
  }

  .projectTitle {
    font-size: 24px;
  }

  .projectDescription {
    font-size: 14px;
  }

  .projectReadMore {
    font-size: 14px;
  }

  .projectReadMoreWrapper {
    margin-left: -10px;
    margin-top: -20px;
    padding-bottom: 30px;
  }

  .projectBottomBorder {
    width: 90%; /* Ensure this is only applied on mobile */
    border-bottom: 1px solid #ccc;
    margin: 0 auto; /* Center the border */
    padding-bottom: 10px; /* Add some padding for spacing */
  }

  /* Contact */
  .my5 {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .lg6 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .mapResponsive {
    height: 150px;
    width: 100%;
  }

  .buttonCustom {
    width: 100%;
  }

  .cardTitle1,
  .cardTitle {
    font-size: 24px;
  }

  .cardText1,
  .cardText {
    font-size: 16px;
  }

  .fa2x {
    font-size: 1.5em;
  }
}
