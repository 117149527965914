/*Top banner*/
.top-banner {
  height: 630px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center; /* Center the content horizontally */
  overflow: hidden;
  z-index: 1;
  background-image: url("assets/images/mirrored_bannerimg.webp");
  background-size: cover; /* Cover the entire element */
  background-repeat: no-repeat; /* Do not repeat the image */
  padding-top: 80px; /* Adjusted for the header height */
  background-attachment: fixed; /* Make the background fixed during scroll */
}

.top-banner::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(135deg, #192c3b, #788fa3);
  opacity: 0.92; /* Adjust the opacity for desired transparency */
  z-index: 2;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the container */
  justify-content: center; /* Center content vertically */
  width: 100%;
  height: 100%;
  z-index: 5;
}

.heading-container {
  flex: 0 0 auto;
  color: white;
  z-index: 1;
  padding: 80px;
  width: 100%; /* Fixed width for the text container */
  max-width: 1465px; /* Optional max-width for larger screens */
  text-align: left; /* Left-align the text */
}

.banner-heading {
  font-size: 52px;
  margin-bottom: 30px;
}

.banner-heading1 {
  font-size: 22px;
  margin-bottom: 40px;
  font-weight: 500;
  max-width: 600px;
}

.arrow-container {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
}

.arrow-down {
  animation: bounce 1s infinite;
  color: white; /* or any other color you prefer */
  font-size: 30px; /* Adjust size as needed */
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

/* INFO 1 */

.info1-container {
  background-color: #f7f7f7;
  height: 550px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 80px;
}

.info1-content {
  display: flex;
  justify-content: space-between;
  max-width: 1380px;
  width: 100%;
}

.info1-left {
  flex: 1;
  font-size: 42px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 1.25;
  background: #34495e;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  max-width: 540px;
  text-align: left;
  padding-right: 20px;
}

.info1-right {
  flex: 1;
  font-size: 18px;
  color: #34495e;
  line-height: 1.6;
  letter-spacing: 0.5px;
  font-weight: 350;
  text-align: left;
  padding-left: 20px;
  padding-right: 80px;
  max-width: 700px;
}

@media (max-width: 768px) {
  .info1-container {
    height: auto; /* Adjust height to auto to fit content */
    padding: 20px; /* Add padding to ensure content isn't too close to edges */
  }

  .info1-content {
    flex-direction: column; /* Stack the content vertically */
    align-items: flex-start; /* Align items to the left */
  }

  .info1-left {
    font-size: 28px; /* Reduce font size for mobile */
    padding-right: 0; /* Remove right padding */
    margin-bottom: 20px; /* Add space between the two sections */
    text-align: center; /* Center align text for better readability */
    -webkit-background-clip: initial; /* Reset background clip */
    -webkit-text-fill-color: #34495e; /* Use the text color directly */
    background: none; /* Remove the background effect on mobile */
  }

  .info1-right {
    font-size: 16px; /* Slightly reduce font size */
    padding-left: 0; /* Remove left padding */
    padding-right: 0; /* Remove right padding */
    text-align: center; /* Center align text for better readability */
  }
}

.info2-container {
  background-color: #f7f7f7;
  height: 600px;
}

.info3-container {
  background-color: #ffffff;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: visible;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.info3-content {
  max-width: 700px;
  text-align: center;
}

.info3-title {
  color: #34495e;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1px;
  margin-bottom: 20px;
}

.info3-text {
  font-size: 42px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 1.25;
  margin-bottom: 40px;
  color: #34495e;
}

.underline {
  position: relative;
  display: inline-block;
}

.underline::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 4px; /* Tjockleken på understrykningen */
  background: linear-gradient(45deg, #192c3b, #556a7c);
  text-underline-offset: 4px; /* Justerar avståndet mellan texten och understrykningen */
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.6s ease;
}

.animate-underline::after {
  transform: scaleX(1);
}

.info3-button {
  padding: 15px 45px;
  font-family: "Montserrat", Arial, sans-serif;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1.2px;
  color: #ffffff;
  background-color: #2c3e50;
  border: none;
  border-radius: 50px;
  transition: transform 0.3s ease, background-color 0.3s ease,
    box-shadow 0.3s ease;
  box-shadow: 0 5px 20px rgba(44, 62, 80, 0.15);
  text-decoration: none;
  z-index: 10;
}

.info3-button:hover {
  background-color: #1a2433;
  transform: translateY(-3px) !important;
  box-shadow: 0 5px 15px rgba(26, 36, 51, 0.2);
  color: #ffffff;
}

.info3-button:focus {
  outline: none;
}

.info3-button:active {
  transform: translateY(2px);
  box-shadow: 0 3px 10px rgba(26, 36, 51, 0.2);
}

@media (max-width: 768px) {
  .info3-container {
    height: auto; /* Allow the container to adjust to its content */
    padding: 20px; /* Add padding to the container */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .info3-content {
    max-width: 100%; /* Make sure the content spans the width of the screen */
    text-align: center;
  }

  .info3-title {
    font-size: 18px; /* Increase font size for better readability */
    margin-bottom: 15px; /* Adjust margin */
  }

  .info3-text {
    font-size: 28px; /* Reduce font size for mobile */
    line-height: 1.4; /* Slightly increase line-height for better readability */
    margin-bottom: 30px; /* Adjust margin */
    padding: 0 10px; /* Add padding to ensure the text isn't too close to the screen edges */
  }

  .underline::after {
    height: 3px; /* Adjust thickness of the underline */
  }

  .info3-button {
    padding: 10px 20px; /* Keep padding consistent */
    font-size: 16px; /* Slightly reduce the font size */
    border-radius: 25px; /* Adjust border-radius for smaller screens */
  }
}

/*Steps delen*/
.contact-container {
  padding: 175px;
  display: flex; /* Activate Flexbox */
  flex-direction: column; /* Set the flex direction to column */
  justify-content: center; /* Vertically align the content in the center */
  align-items: center; /* Horizontally align the content in the center */
  position: relative;
  max-width: 1550px;
  margin: 0 auto;
  overflow: hidden;
}

.contact-container2 {
  margin: 0 auto;
  background-color: #2c3e50;
}

.contact-title {
  font-size: 45px;
  text-align: center;
  margin-bottom: 120px;
  color: white;
  font-weight: 800;
  letter-spacing: 0.8px;
  border-bottom: 3px solid #3e5770;
}

.step-wrapper {
  display: flex;
  align-items: center;

  max-width: 1420px;
}

.step-wrapper:nth-child(odd) {
  flex-direction: row-reverse;
  margin-right: -300px;
}
.step-wrapper:nth-child(even) {
  margin-left: -300px;
}

.step-number {
  font-size: 170px;
  color: white;
  margin-right: 20px;
  line-height: 1;
  position: relative;
  top: -190px;
  z-index: 3;
}

.step-wrapper:nth-child(odd) .step-number {
  left: -480px;
}

.step-wrapper:nth-child(even) .step-number {
  right: -10px;
}

.text-wrapper {
  flex: 1;
  max-width: 900px;
  width: auto;
}

.smaller-title {
  font-size: 40px;
  text-align: left;
  margin-bottom: 10px;
  color: white;
}

.description-text {
  color: white;
}

.image-container {
  width: 350px;
  height: 450px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.step-wrapper:nth-child(odd) .image-container {
  left: 20px;
  margin-right: 90px;
}

.step-wrapper:nth-child(even) .image-container {
  left: 80px;
  margin-left: 125px; /* Adjusting the gap between the image and the text for odd-numbered steps */
}

.image-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.icon-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
  z-index: 0;
}

.icon-step-container {
  display: flex;
  align-items: center; /* Vertically center child items */
  justify-content: center; /* Horizontally center child items */
  position: relative;
}

/* WebsiteShowcase styles */
.showcase-section {
  background: linear-gradient(135deg, #243e54, #51616f);
  padding-top: 130px;
  padding-bottom: 25px;
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.showcase-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 10px;
}

.devices {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-content: center;
  margin-left: -100px;
  padding-top: 100px;
  padding-bottom: 120px;
}

.phone-mockup {
  height: 250px;
  margin-top: 130px;
  margin-left: -210px;
}

.computer-mockup {
  width: 700px;
  overflow: hidden;
  z-index: 1;
  margin-right: -340px;
}

.bottom-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: -80px;
  padding-bottom: 40px;
  z-index: 99;
}

.slider-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sample-slider {
  appearance: none;
  width: 200px;
  height: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 1;
  transition: value 0.3s linear;
}

.sample-slider::-webkit-slider-thumb {
  appearance: none;
  width: 25px;
  height: 25px;
  background: #f7f7f7;
  cursor: pointer;
  border-radius: 50%;
}

.sample-slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #2c3e50;
  cursor: pointer;
  border-radius: 50%;
}

.slider-labels {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin-top: 10px;
  margin-left: 8px;
  color: #f7f7f7;
}

.pause-button {
  background-color: transparent;
  color: #f7f7f7;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 20px;
  transition: background-color 0.3s;
}

.description-section {
  text-align: center;

  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto; /* Center horizontally */
  padding-top: 20px;
  margin-bottom: -80px;
}

.description-section h3 {
  color: #f7f7f7;
  font-size: 40px;
  font-weight: bold;
  margin-top: -100px;
}

/*Our values section*/
.values-section {
  padding: 80px 50px;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffffff;
  font-family: "Helvetica Neue", sans-serif; /* A modern sans-serif font */
}

.values-title {
  font-size: 42px;
  font-weight: 800;
  margin-bottom: 70px;
  text-align: center;
  text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.1);
  letter-spacing: 1.5px;
  color: #34495e;
}

.values-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  max-width: 1275px;
  gap: 20px;
}

.value-item {
  flex: 1 1 calc(33.33% - 40px);
  max-width: calc(33.33% - 40px);
  background-color: #f7f7f7;
  padding: 15px;
  border-radius: 15px;
  transition: all 0.4s ease;
  border: 2px solid rgba(255, 255, 255, 0.05);
  color: #34495e;
  text-align: center;
  position: relative;
  margin-bottom: 50px;
}

.values-container:last-child {
  justify-content: center;
}

.value-list {
  list-style-type: none; /* Remove default bullet points */
  padding: 0; /* Remove default padding */
  margin: 0;
  width: 100%; /* Ensure the list takes up the full width */
}

.value-list li {
  display: flex;
  align-items: flex-start; /* Align items to the top of the li */
  font-size: 18px;
  margin-bottom: 14px; /* Space between list items */
  justify-content: flex-start; /* Align the icon and text to the start */

  line-height: 1.5;
  position: relative;
  padding-left: 30px; /* Space for the icon */
  text-align: left; /* Ensure text is left-aligned */
  word-wrap: break-word; /* Allows text to wrap to the next line if necessary */
  max-width: 310px;
}

.value-item h3 {
  font-size: 30px;
  margin-bottom: 40px;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  letter-spacing: 1px;
}

.checkmark-icon {
  font-size: 24px; /* Set the size of the icon */
  color: #34495e; /* Set the color of the icon */
  position: absolute;
  left: 0; /* Align to the left within the li */
  top: 2px; /* Align to the top within the li */
}

.value-item p {
  font-size: 18px;
  line-height: 1.85;
  opacity: 0.92;
}

.value-item:hover {
  transform: translateY(-12px);
  border: 2px solid rgba(255, 255, 255, 0.1); /* Darkening the border on hover */
}

/* If you're planning to add icons */
.icon-style {
  position: absolute;
  bottom: 20px; /* Positioning 20px from the bottom */
  left: 50%;
  transform: translateX(-50%); /* Centering horizontally */
  font-size: 30px;
  color: #34495e;
}

.title-container {
  position: relative;
  display: flex;
  align-items: left;
}

.values-title {
  /* Your existing styles for values-title */
  position: relative; /* Needed so z-index works properly */
}

@media (max-width: 830px) {
  .devices {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-left: 0;
    padding-top: 60px;
    padding-bottom: 20px;
  }

  .phone-mockup {
    width: 15%;
    height: auto;
    margin-top: 0;
    margin-left: 0;
    margin-top: 50px;
  }

  .computer-mockup {
    width: 75%;
    height: auto;
    margin-right: 0;
  }

  .bottom-section {
    flex-direction: column;
    margin-top: 0;
    padding-bottom: 20px;
  }

  .description-section {
    font-size: 24px;
    margin-bottom: -40px;
  }

  .description-section h3 {
    font-size: 28px;
  }

  .sample-slider {
    width: 150px;
  }

  .pause-button {
    font-size: 16px;
  }
}
/*FAQ sektionen*/
.faq-section {
  padding: 85px;
  text-align: center;
  background-color: #f7f7f7;
  margin-bottom: -80px;
}

.faq-section h2 {
  font-size: 30px;
  color: #243e54;
  margin-bottom: 20px;
  font-weight: bold;
}

.faq-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  max-width: 1300px;
  margin: 0 auto;
}

.faq-item {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s;
  position: relative;
  box-sizing: border-box;
  background: white;
}

.faq-item:hover {
  background-color: #f6f6f6;
}

.faq-question {
  margin: 0;
  color: #2c3e50;
  font-size: 18px;
}

.faq-answer {
  margin-top: 10px;
  text-align: left;
  z-index: 299;
}

.icon-indicator {
  position: absolute;
  top: 10px;
  right: 10px;
  transition: transform 0.3s;
  color: #192c3b;
}

/*Sektionen innan footern*/
.new-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 85px;
  background-color: #f7f7f7; /* Neutral light grey background to reduce starkness of pure white */
}

.content-section {
  width: 100%;
  text-align: center;
  padding: 3rem;
  background-color: #ffffff; /* Pure white content box */
  border-radius: 10px;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.05); /* Soft shadow for depth */
  max-width: 1300px;
}

.content-section h2 {
  font-size: 42px;
  color: #2c3e50; /* Dark color for contrast */
  font-weight: 800; /* Strong font weight for titles */
  margin-bottom: 2rem;
  line-height: 1.2;
}

.content-section p {
  font-size: 20px;
  line-height: 1.7;
  color: #555555; /* Neutral readable color */
  margin: 0 auto 2.5rem; /* Space before the button */
  max-width: 800px;
  font-weight: 300; /* Lighter font-weight to contrast the title */
}

.content-section-button {
  padding: 15px 45px;
  font-family: "Montserrat", Arial, sans-serif;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1.2px; /* Increased spacing for readability in uppercase */
  color: #ffffff;
  background-color: #2c3e50; /* Theme color for the button */
  border: none;
  border-radius: 50px; /* Larger border radius for pill-shaped button */
  transition: all 0.3s ease-in-out;
  box-shadow: 0 5px 20px rgba(44, 62, 80, 0.15); /* Box-shadow for a 3D look */
}

.content-section-button:hover {
  background-color: #1a2433;
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(26, 36, 51, 0.2);
}

.content-section-button:focus {
  outline: none;
}

.content-section-button:active {
  transform: translateY(2px);
  box-shadow: 0 3px 10px rgba(26, 36, 51, 0.2);
}

@media (max-width: 1000px) {
  .showcase-container {
    flex-direction: column;
    align-items: center; /* Center everything horizontally */
  }

  .banner-heading {
    font-size: 35px;
    margin-top: -50px;
  }
  .banner-heading1 {
    font-size: 18px;
    width: 100%;
    max-width: 100%;
  }
  .content-wrapper {
    margin-left: -50px;
  }
  .showcase-section {
    width: 100%;
  }
  .section-title {
    font-size: 30px;
    margin-bottom: 75px;
  }

  .contact-container {
    padding: 55px;
  }

  .contact-title {
    font-size: 30px;
    margin-bottom: 60px;
  }

  .step-wrapper {
    flex-direction: column; /* Stack elements vertically */
    align-items: center; /* Center items horizontally */
    width: 100%;
  }

  .step-wrapper:nth-child(odd),
  .step-wrapper:nth-child(even) {
    flex-direction: column; /* Override previous styles and stack vertically */
    margin-left: 0;
    margin-right: 0;
    left: 0;
  }

  .step-wrapper:nth-child(odd) .step-number {
    left: 110px;
  }

  .step-wrapper:nth-child(even) .step-number {
    left: 110px;
  }

  .step-number {
    top: 40px;
  }

  .image-container,
  .text-wrapper {
    order: 2; /* Place text-wrapper below */
    margin: 0; /* Reset any specific margins */
    left: 0; /* Reset */
    width: 100%; /* Decrease width for better fitting */
    text-align: center;
  }

  .text-wrapper {
    order: 1; /* Place text-wrapper above */
    text-align: left;
    margin-bottom: 20px;
  }
  .image-container {
    display: none;
  }

  .icon-image {
    width: 100%;
    height: auto;
  }

  .smaller-title {
    font-size: 28px;
  }

  .faq-grid {
    grid-template-columns: 1fr; /* Makes it a single column layout */
    gap: 0px; /* Adjust gap if needed for mobile */
  }

  .faq-section h2 {
    font-size: 24px; /* Adjust title size for mobile if desired */
  }
  .faq-section {
    padding: 50px;
  }

  .new-section {
    padding: 50px;
    margin-top: 30px;
  }

  .faq-item {
    margin-bottom: 10px; /* Adjust margin for mobile if desired */
    max-width: 100%; /* Adjust the width of the questions for mobile */
    margin-left: auto;
    margin-right: auto;
  }
  .icon-indicator {
    margin-left: 10px;
  }
  .faq-question {
    width: calc(
      100% - 30px
    ); /* Adjust based on how the layout looks on mobile, reducing space for the icon */
  }

  .content-section {
    width: 100%; /* Increase width on smaller screens */
    padding: 1.5rem; /* Less padding for more compact look */
  }

  .content-section h2 {
    font-size: 30px; /* Slightly smaller font-size for headers */
    margin-bottom: 1.5rem; /* Adjust margin for a more compact look */
  }

  .content-section p {
    font-size: 18px; /* Adjusting the paragraph font size for mobile screens */
    margin: 0 auto 1.5rem; /* Adjust margin for a more compact look */
  }

  .content-section-button {
    padding: 10px 30px; /* Adjusting padding for the button */
    font-size: 16px; /* Adjusting font size for the button */
  }

  .top-banner {
    background-color: transparent;
    background-attachment: scroll;
    background-image: contain;
    width: auto;
  }

  /*Websiteshowcase2*/
  .values-section {
    padding: 120px 20px;
  }

  .values-title {
    font-size: 32px;
    margin-bottom: 40px;
  }

  .values-container {
    flex-direction: column;
    gap: 75px;
    align-items: center;
  }

  .value-item {
    max-width: 100%;
    padding: 30px;
  }

  .value-item h3 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .value-item p {
    font-size: 16px;
  }
}
