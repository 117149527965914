.about-us-container {
  font-family: "Montserrat";
  position: relative;
  z-index: 1;
}

.container {
  padding: 40px 82px;
  background-color: #f4f4f4;
}

.title {
  margin-bottom: 1em;
  text-align: center;
  margin-top: 3em;
  color: white;
  font-size: 42px;
  font-weight: bold;
  margin-top: 40px;
}

.title2 {
  margin-bottom: 3em;
  text-align: center;
  margin-left: 8em;
  margin-right: 8em;
  margin-top: -30px;
  color: white;
}

.block {
  max-width: 1300px; /* Max width for larger screens */
  margin: 0px auto;
  padding: 20px;
  background: linear-gradient(105deg, #122635, #617b90);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: center; /* Align items to the left within the container */
}

/*Top banner*/
.top-banner {
  height: 630px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center; /* Center the content horizontally */
  overflow: hidden;
  z-index: 1;
  background-image: url("assets/images/mirrored_bannerimg.webp");
  background-size: cover; /* Cover the entire element */
  background-repeat: no-repeat; /* Do not repeat the image */
  padding-top: 80px; /* Adjusted for the header height */
  background-attachment: fixed; /* Make the background fixed during scroll */
}

.top-banner::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(135deg, #192c3b, #788fa3);
  opacity: 0.92; /* Adjust the opacity for desired transparency */
  z-index: 2;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the container */
  justify-content: center; /* Center content vertically */
  width: 100%;
  height: 100%;
  z-index: 5;
}

.heading-container {
  flex: 0 0 auto;
  color: white;
  z-index: 1;
  padding: 80px;
  width: 100%; /* Fixed width for the text container */
  max-width: 1465px; /* Optional max-width for larger screens */
  text-align: left; /* Left-align the text */
}

.banner-heading {
  font-size: 52px;
  margin-bottom: 30px;
}

.banner-heading1 {
  font-size: 22px;
  margin-bottom: 40px;
  font-weight: 500;
  max-width: 600px;
}

.arrow-container {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
}

.arrow-down {
  animation: bounce 1s infinite;
  color: white; /* or any other color you prefer */
  font-size: 30px; /* Adjust size as needed */
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

/* Contact form */
.form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 0;
  min-height: 40vh;
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  background-color: transparent;
  padding: 2em;
}

.inputGroup {
  flex: 1;
  width: calc(50% - 0.5em); /* Adjusted width for inputs */
  margin-bottom: 1em;
}

.input,
.textareaInput {
  width: 100%;
  padding: 0.5em 0;
  border: none;
  border-bottom: 1px solid white;
  background: transparent;
  color: white;
  font-size: 1rem;
  transition: border-color 0.3s ease;
  -webkit-appearance: none; /* Remove default styling on iOS */
  appearance: none; /* Remove default styling */
  border-radius: 0; /* Remove any border radius */
  box-shadow: none; /* Remove any box shadow */
  outline: none; /* Remove default outline */
}

.input::placeholder,
.textareaInput::placeholder {
  color: white;
  opacity: 0.7;
}

.input:focus,
.textareaInput:focus {
  border-color: #007bff;
  outline: none;
}

.textareaGroup {
  width: 100%;
  margin-bottom: 1em;
}

.textareaInput {
  height: 150px;
  resize: vertical;
}

.submit {
  padding: 15px 45px;
  font-family: "Montserrat", Arial, sans-serif;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1.2px;
  color: white;
  background-color: transparent;
  border: none;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
  border: 1px solid white;
  cursor: pointer;
  margin-top: 2em;
  align-self: center;
  text-align: center; /* Ensure text is centered */
}

.submit:hover {
  background-color: white;
  transform: translateY(-3px);
  color: #122635;
}

.submit:focus {
  outline: none;
}

.submit:active {
  transform: translateY(2px);
  box-shadow: 0 3px 10px rgba(0, 86, 179, 0.4);
}

.row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 1em; /* Create gap between input fields */
}

.checkboxContainer {
  display: flex;
  align-items: center;
  margin-top: 1em;
  color: #ffffff;
  font-size: 0.8rem; /* Smaller font size for checkbox text */
}

.checkbox {
  margin-right: 0.5rem;
  width: 15px;
  height: 15px;
  border: 2px solid #ffffff;
  background: transparent;
  appearance: none;
  cursor: pointer;
  position: relative;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.checkbox:checked {
  background-color: transparent;
  border-color: white;
}

.checkbox:checked::after {
  content: "";
  width: 4px; /* Adjusted width */
  height: 8px; /* Adjusted height */
  border: solid white;
  border-width: 0 2px 2px 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
}

.checkbox + label {
  cursor: pointer;
}

.checkbox + label a {
  color: white; /* Change link color to white */
}

.errorMessage {
  color: red;
  margin-top: 0.5rem;
  text-align: center;
}

.checkbox + label a:hover {
  color: #007bff; /* Optional: Change link color on hover */
}

/* KONTAKT DETALJER*/

.contactDetails {
  display: flex;
  flex-direction: column;
  margin-bottom: 2em;
  justify-content: center; /* Center horizontally */
  flex: 1; /* Allow it to take available space */

  max-width: 300px;
}

.contactDetails h2 {
  margin-bottom: 20px;
  color: white;
  text-align: left;
  font-size: 30px;
  font-weight: bold;
}

.detailItem {
  display: flex;
  align-items: center;
  margin-bottom: 1em;
  color: white;
  width: 100%;
}

.detailItem span {
  color: white;
  font-size: 20px;
  margin-left: 10px; /* Add some space between the icon and the text */
}

.icon {
  font-size: 1.2em; /* Adjust size as needed */
  color: white; /* Adjust color as desired */
  font-size: 30px;
  min-width: 30px; /* Ensure consistent width */
  text-align: center; /* Center the icon */
}

/*MAP*/

.mapContainer {
  flex: 1; /* Allow the map to take up available space */
  max-width: 600px; /* Set a reasonable max-width */
  height: 400px; /* Set a fixed height */
}

.mapResponsive {
  position: relative;
  overflow: hidden;
  padding-top: 0; /* Remove padding-top to use fixed height */
  height: 100%; /* Full height for container */
}

.mapResponsive iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.detailsFaqContainer {
  display: flex;
  flex-direction: row; /* Arrange elements in a row */
  justify-content: space-between; /* Add space between elements */
  align-items: flex-start; /* Align items to the top */
  width: 96.7%;
  padding: 40px;
  margin-bottom: -20px;
  margin-top: 40px;
  position: relative; /* Required for the pseudo-element positioning */
}

.detailsFaqContainer::before {
  content: "";
  position: absolute;
  top: 0;
  left: calc(3% + 3px); /* Adjust the left to make it a bit shorter */
  width: calc(94% - 6px); /* Adjust the width to make it a bit shorter */
  border-top: 1px solid white; /* The top border */
}

/* Fade in when confirmed on the form*/
/* Add to your CSS file */

.popup {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 100px; /* Position it at the top instead of bottom */
  right: 20px;
  min-width: 300px; /* Set a minimum width */
  padding: 15px 30px; /* Increase padding for a larger popup */
  background-color: #ffffff; /* Success green background */
  border-radius: 4px; /* Rounded edges */
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.2); /* Slight shadow */
  color: #34495e;
  opacity: 0; /* Start fully transparent */
  visibility: hidden; /* Start hidden */
  transition: visibility 0s linear 0.5s, opacity 0.5s linear;
  z-index: 1000; /* Ensure it's above other content */
}

.popupIcon {
  font-size: 1.5rem; /* Increase icon size */
}

.closeIcon {
  font-size: 1.5rem; /* Increase icon size */
  cursor: pointer;
}

.popupMessage {
  font-size: 1rem; /* Increase message text size */
  margin: 0 10px; /* Add some horizontal spacing */
}

.fadeIn {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.5s linear;
}

.fadeOut {
  opacity: 0;
  visibility: hidden;
  transition: visibility 0s linear 0.5s, opacity 0.5s linear;
}

.checkboxContainer {
  margin-top: 1rem;
  display: flex;
  align-items: center;
}

.checkbox {
  margin-right: 0.5rem;
}

.errorMessage {
  color: red;
  margin-top: 0.5rem;
}

@media screen and (max-width: 1280px) {
  .mapContainer {
    visibility: hidden;
  }

  .detailsFaqContainer {
    flex-direction: column; /* Stack elements vertically */
    align-items: center; /* Center align items */
    text-align: center; /* Center align text */
  }

  .detailsFaqContainer::before {
    width: 50%; /* Adjust the top border width */
    left: 25%; /* Center the top border */
  }

  .contactDetails {
    display: flex;
    flex-direction: column;
    margin-bottom: 2em;
    justify-content: center;
    align-items: center;
    max-width: 300px;
    margin: 0 auto; /* Center the contact details horizontally */
  }

  .contactDetails h2 {
  }
}

/* Media Query for screens smaller than or equal to 810px */
@media screen and (max-width: 810px) {
  .row {
    flex-direction: column;
  }
  .row .inputGroup {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  }
  .textareaGroup {
    width: 100%;
  }
  .textareaInput {
    height: 100px;
    width: 100%; /* Ensure the message input has the same width */
  }
  .checkboxContainer {
    flex-direction: row; /* Change direction to row */
    align-items: center;
    font-size: 0.7rem; /* Even smaller font size for mobile */
  }
  .submit {
    width: 100%;
    padding: 1em;
    margin-top: 1em;
    font-size: 1rem; /* Adjust font size for mobile */
    letter-spacing: 1px; /* Adjust letter-spacing for mobile */
  }
  .container {
    padding: 20px;
  }
  .mapContainer {
    visibility: hidden;
  }
  /* Adjust title styles for mobile */
  .title {
    font-size: 1.5em; /* Smaller font size for mobile */
    margin-top: 2em; /* Adjust top margin */
  }
  .title2 {
    font-size: 0.8em; /* Smaller font size for mobile */
    margin-left: 2em; /* Adjust margins for better fit */
    margin-right: 2em;
    margin-top: -10px;
    margin-bottom: -10px;
  }
}
