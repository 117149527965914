/*Top banner*/
.top-banner {
  height: 630px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center; /* Center the content horizontally */
  overflow: hidden;
  z-index: 1;
  background-image: url("../../assets/images/mirrored_bannerimg.webp");
  background-size: cover; /* Cover the entire element */
  background-repeat: no-repeat; /* Do not repeat the image */
  padding-top: 80px; /* Adjusted for the header height */
  background-attachment: fixed; /* Make the background fixed during scroll */
}

.top-banner::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(135deg, #192c3b, #788fa3);
  opacity: 0.92; /* Adjust the opacity for desired transparency */
  z-index: 2;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the container */
  justify-content: center; /* Center content vertically */
  width: 100%;
  height: 100%;
  z-index: 5;
}

.heading-container {
  flex: 0 0 auto;
  color: white;
  z-index: 1;
  padding: 80px;
  width: 100%; /* Fixed width for the text container */
  max-width: 1465px; /* Optional max-width for larger screens */
  text-align: left; /* Left-align the text */
}

.banner-heading {
  font-size: 52px;
  margin-bottom: 30px;
}

.banner-heading1 {
  font-size: 22px;
  margin-bottom: 40px;
  font-weight: 500;
}

.arrow-container {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
}

.arrow-down {
  animation: bounce 1s infinite;
  color: white; /* or any other color you prefer */
  font-size: 30px; /* Adjust size as needed */
}

.arrow {
  transition: transform 0.4s ease, margin-left 0.4s ease; /* Smooth transition */
}

.articlePreview:hover .arrow {
  transform: translateX(7px); /* Move arrow slightly on hover */
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

/* Testimonial.css */

.testimonial-container {
  max-width: 800px;
  margin: 50px auto;
  text-align: center;
  font-family: "Arial", sans-serif;
}

.testimonial-text {
  font-size: 24px;
  font-style: italic;
  color: #333;
  margin-bottom: 20px;
  position: relative;
  padding: 0 20px;
}

.testimonial-text::before {
  content: "“";
  font-size: 40px;
  color: #3b82f6;
  position: absolute;
  top: -10px;
  left: -10px;
}

.testimonial-author {
  font-size: 18px;
  color: #666;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

.image-text-container {
  display: flex;
  align-items: flex-start; /* Align items to the top */
  justify-content: center;
  margin: 20px auto; /* Center the container horizontally */

  max-width: 1300px; /* Set a maximum width */
  margin-top: 120px;
  margin-bottom: 120px;
}

.text-section {
  flex: 1;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 40%;
  align-items: center;
  padding: 20px;
  letter-spacing: 1.2px;
}

.text-section h2 {
  margin-bottom: 10px;
  width: 100%;
  letter-spacing: 1.2px;
  font-family: "Montserrat", Arial, sans-serif;
  font-weight: 600;
  text-shadow: 0 0 8px rgba(255, 255, 255, 0.1);
  color: #2c3e50;
  font-size: 32px;
}

.text-section p {
  color: #2c3e50;
  letter-spacing: 1.2px;

  font-size: 16px;
}

.image-section {
  flex: 1;
  display: flex;
  justify-content: center;
  max-width: 50%; /* Adjust width as needed */
  overflow: hidden; /* Hide overflow of zoomed image */
  border-radius: 20px;
  padding: 20px;
  margin-top: 40px;
}

.image-section img {
  width: 100%;
  height: auto;

  padding: 20px;
  transform: scale(1.3); /* Zoom in the image */
}

.image-container-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    135deg,
    #192c3b,
    #788fa3
  ); /* Replace with your desired background color */
  border-radius: 30px; /* Adjust as needed */
  max-width: 1180px; /* Fixed width */
  padding: 20px 10px; /* Add padding */
  margin: 20px auto; /* Center the div horizontally */
  margin-top: 120px;
  margin-bottom: 120px;
  max-height: 500px;
}

/* Add media query for smaller screens */
@media (max-width: 1068px) {
  .image-container-wrapper {
    padding: 20px 10px; /* Adjust the padding for smaller screens */
    margin: 20px 10px; /* Add horizontal margin to ensure space on the sides */
    margin-top: -120px;
    margin-bottom: -20px;
  }
  .image-container img {
    transform: scale(1.1);
  }
  .image-section {
    display: none;
  }
}

.image-container img {
  display: block;
  max-width: 100%;
  height: auto;
  transform: scale(0.85);
}

@media screen and (max-width: 810px) {
  .banner-heading {
    font-size: 35px;
    margin-top: -50px;
  }

  .banner-heading1 {
    font-size: 18px;
    width: 100%;
    max-width: 100%;
  }

  .top-banner {
    background-color: transparent;
    background-attachment: scroll;
    background-image: contain;
    width: auto;
  }

  .image-text-container {
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
  }

  .text-section {
    margin-right: 0;
    margin-bottom: 20px;
    max-width: 100%;
    align-items: center;
    text-align: center;
  }
  .text-section p {
    text-align: left;
  }
  .image-section {
    justify-content: center;
    max-width: 100%;
  }
}
