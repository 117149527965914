@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;700&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Titillium Web", sans-serif;
}

:root {
  --mainColor: #2c3e50;
  --secondaryColor: white;
  --textColor: #eee;
  --hoverColor: rgba(255, 255, 255, 0.1);
  --dropdownBackgroundColor: rgba(44, 62, 80, 0.95);
  --descriptionColor: #ccc; /* Color for the description text */
}

body,
html {
  margin: 0;
  padding: 0;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px; /* Initial height */
  padding: 0 2rem;
  transition: background-color 0.3s ease, height 0.3s ease; /* Add transition for height */
  position: fixed; /* Keep the header fixed at the top */
  top: 0;
  width: 100%;
  z-index: 9999;
}

.transparent {
  background-color: transparent; /* Transparent at the top */
  height: 80px; /* Keep the initial height when at the top */
}

.solid {
  background: linear-gradient(
    4deg,
    rgba(25, 44, 59, 0.9),
    /* rgba format with alpha for transparency */ rgba(65, 85, 103, 0.9)
  );
}

.compact {
  height: 70px; /* Shorter height when scrolling */
}

.logoLink {
  display: flex;
  align-items: center;
  z-index: 999999;
}

.logo {
  width: 110px; /* Adjust logo size when compact */
  height: auto;
  transition: width 0.3s ease; /* Smooth transition for logo size */
}

.nav {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  z-index: 999999;
}

nav a {
  margin: 0 1rem;
  color: var(--textColor);
  text-decoration: none;
  font-size: 22px;
  transition: color 0.3s ease;
}

nav a:hover {
  color: var(--secondaryColor);
}

.nav_btn {
  background: none;
  border: none;
  color: var(--textColor);
  font-size: 2rem;
  cursor: pointer;
  text-align: center;
  margin-top: -10px;
  transition: transform 0.3s ease, top 0.3s ease, padding 0.3s ease;
}

.nav_close_btn,
.nav_open_btn {
  display: none;
}

.topdropdown {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  position: relative;
  font-size: 22px;
  color: var(--textColor); /* Ensure text color matches the other nav items */
}

.dropdown {
  display: flex;
  flex-direction: column;
  position: absolute;
  font-size: 1rem;
  background-color: rgba(
    44,
    62,
    80,
    0.95
  ); /* Slightly transparent background */
  top: 100%;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 999999;
  left: 0;
  width: 300px;
  border-radius: 8px; /* Rounded corners for the dropdown */
  overflow: hidden; /* Ensure child elements don't overflow */
  padding: 0.5rem; /* Reduce padding inside the dropdown */
  max-height: 0;
  opacity: 0;
  visibility: hidden;
  transition: max-height 0.3s ease, opacity 0.3s ease, visibility 0.3s ease;
  color: var(--textColor); /* Ensure text color is consistent */
}

.showDropdown {
  max-height: 500px;
  opacity: 1;
  visibility: visible;
}

.arrowLink {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  transition: background-color 0.15s ease, transform 0.15s ease;
  text-align: left;
  color: var(--textColor); /* Ensure text color is white */
  border-radius: 8px;
  text-decoration: none;
  margin-bottom: 0.5rem;
}

.arrowLink:hover {
  background-color: var(--hoverColor); /* Change hover color */
  transform: scale(1.02); /* Slightly scale up on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add subtle shadow on hover */
}
.arrowLinkTitle {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between; /* Add this to space the title and arrow */
}

.arrowLink span {
  margin-left: 10px;
}

.description {
  color: var(--descriptionColor);
  margin-top: 0.25rem; /* Reduce margin for a more compact look */
  font-size: 0.85rem; /* Reduce font size for descriptions */
}

.languageOptions {
  position: relative;
  display: flex;
  align-items: center;
  z-index: 1000; /* Ensure it's above other elements */
}

.languageButton {
  background: transparent;
  border: none;
  color: var(--textColor);
  font-size: 1.3rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1001; /* Ensure the button is above other elements */
  position: relative;
}

.languageButton:focus {
  outline: none;
}

.languageDropdown {
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: var(–dropdownBackgroundColor);
  top: 100%;
  right: 0;
  width: 120px;
  border-radius: 8px;
  overflow: hidden;
  padding: 0.5rem 0;
  max-height: 0;
  opacity: 0;
  visibility: hidden;
  transition: max-height 0.3s ease, opacity 0.3s ease, visibility 0.3s ease;
  z-index: 999999;
  pointer-events: none;
}

.showLanguageDropdown {
  max-height: 300px;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.languageDropdown li {
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  color: var(–textColor);
  display: flex;
  align-items: center;
  justify-content: center;
}

.languageDropdown li:hover {
  background-color: var(–secondaryColor);
  border-radius: 4px;
}

/* Hide desktop language selector on mobile */
@media only screen and (max-width: 768px) {
  .desktopLanguageOptions {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .nav_btn {
    top: 10px; /* Adjust for smaller screens */
  }

  .compact .nav_btn {
    top: 10px; /* Make sure the button aligns when compact on mobile */
  }
}

/* Hide mobile language selector on desktop */
@media only screen and (min-width: 769px) {
  .mobileLanguageOptions {
    display: none;
  }
}

/* Desktop-specific styles */
@media only screen and (min-width: 769px) {
  .mobileLanguageOptions {
    display: none;
  }

  .desktopLanguageOptions {
    display: flex;
    align-items: center;
    z-index: 1000; /* Ensure it is above other elements */
    position: relative;
  }
}

/* Mobile-specific styles */
@media only screen and (max-width: 768px) {
  header {
    top: 0;
    left: 0;
    right: 0;
  }

  .nav {
    position: fixed;
    top: 0; /* Ensure it takes the full height */
    left: 0;
    width: 100%;
    height: 100vh; /* Ensure it takes the full height */
    background-color: rgba(
      44,
      62,
      80,
      0.95
    ); /* Increase opacity for better visibility */
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: auto; /* Allow scrolling */
    gap: 0.5rem; /* Reduced gap for closer options */
    transform: translateX(-100%);
    transition: transform 0.5s ease;
    z-index: 99999;
    padding-top: 80px; /* Adjust padding to account for the header height */
    color: var(--textColor); /* Ensure text color is consistent */
  }

  .responsive_nav {
    transform: translateX(0);
  }

  .nav_close_btn {
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 9999999;
    background: none;
    border: none;
    font-size: 2rem;
    cursor: pointer;
  }

  .nav_close_btn:hover {
    color: rgb(198, 63, 63);
  }

  .nav_open_btn {
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 99998;
    background: none;
    border: none;
    font-size: 2rem;
    cursor: pointer;
  }

  .logoLink {
    z-index: 99998;
  }

  .responsive_nav .nav_close_btn {
    display: block;
  }

  .responsive_nav .nav_open_btn {
    display: none;
    overflow: visible;
  }

  .languageOptions {
    display: none;
  }

  .responsive_nav .languageOptions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10002;
    background-color: var(
      --mainColor
    ); /* Ensure background color matches the menu */
    padding: 0.5rem 1rem;
    border-radius: 5px;
  }

  nav a,
  .topdropdown {
    font-size: 1.5rem;
    text-align: center;
    width: 100%;
    display: block;
    padding: 0.5rem 0; /* Reduced padding for closer options */
  }

  .topdropdown {
    margin-top: 0; /* Remove margin-top for top item */
    margin-bottom: -20px;
  }

  .dropdown {
    position: static;
    width: 100%;
    margin: 0 auto; /* Center the dropdown */
    background-color: var(--dropdownBackgroundColor);
    border-radius: 8px;
    overflow: hidden;

    box-shadow: none; /* Add shadow for better depth */
  }

  .showDropdown .dropdown {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem; /* Reduced gap for closer options */
  }

  .showDropdown {
    width: 100%;
    align-items: center;
  }

  .mobileLanguageOptions {
    display: flex;
    justify-content: center;
    padding: 0.5rem 0; /* Reduced padding */
    background-color: var(
      --dropdownBackgroundColor
    ); /* Ensure it matches the menu */
    border-top: 1px solid var(--hoverColor);
  }

  /* Improved visibility for the mobile menu */
  .nav a,
  .topdropdown,
  .languageOptions {
    color: var(--textColor); /* Ensure text color is consistent */
  }

  .nav {
    background-color: var(
      --mainColor
    ); /* Ensure the background color is opaque */
  }

  .responsive_nav a,
  .responsive_nav .topdropdown {
    color: var(--textColor); /* Ensure text color is consistent */
  }

  .responsive_nav .dropdown {
    background-color: rgba(
      52,
      73,
      94,
      0.95
    ); /* Ensure dropdown has proper background */
  }

  .responsive_nav .languageDropdown {
    background-color: rgba(52, 73, 94, 0.95);
  }

  nav a,
  .topdropdown {
    position: static; /* Remove relative positioning on mobile */
  }

  nav a::after,
  .topdropdown::after {
    content: none; /* Remove the underline on mobile */
    display: none; /* Ensure the pseudo-element is not displayed */
  }

  nav a:hover::after,
  .topdropdown:hover::after {
    width: 0; /* Ensure no hover effect */
    left: 50%;
  }
}

/* Add this CSS to your existing stylesheet */

/* Base styles for the links */
nav a,
.topdropdown {
  position: relative;
  color: var(--textColor);
  text-decoration: none;
  padding: 5px 0;
}

/* Add the underline */
nav a::after,
.topdropdown::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  height: 2px;
  width: 0;
  background-color: var(--secondaryColor);
  transition: width 0.4s ease, left 0.4s ease;
}

/* Hover effect where the underline expands outward from the center */
nav a:hover::after,
.topdropdown:hover::after {
  width: 100%;
  left: 0;
}
