/*Top banner*/
.top-banner {
  height: 630px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center; /* Center the content horizontally */
  overflow: hidden;
  z-index: 1;
  background-image: url("assets/images/mirrored_bannerimg.webp");
  background-size: cover; /* Cover the entire element */
  background-repeat: no-repeat; /* Do not repeat the image */
  padding-top: 80px; /* Adjusted for the header height */
  background-attachment: fixed; /* Make the background fixed during scroll */
}

.top-banner::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(135deg, #192c3b, #788fa3);
  opacity: 0.92; /* Adjust the opacity for desired transparency */
  z-index: 2;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the container */
  justify-content: center; /* Center content vertically */
  width: 100%;
  height: 100%;
  z-index: 5;
}

.heading-container {
  flex: 0 0 auto;
  color: white;
  z-index: 1;
  padding: 80px;
  width: 100%; /* Fixed width for the text container */
  max-width: 1465px; /* Optional max-width for larger screens */
  text-align: left; /* Left-align the text */
}

.banner-heading {
  font-size: 52px;
  margin-bottom: 30px;
}

.banner-heading1 {
  font-size: 22px;
  margin-bottom: 40px;
  font-weight: 500;
}

.arrow-container {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
}

.arrow-down {
  animation: bounce 1s infinite;
  color: white; /* or any other color you prefer */
  font-size: 30px; /* Adjust size as needed */
}

.arrow {
  transition: transform 0.4s ease, margin-left 0.4s ease; /* Smooth transition */
}

.articlePreview:hover .arrow {
  transform: translateX(7px); /* Move arrow slightly on hover */
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

/* Targeting links directly within the banner-heading1 class */
.banner-heading1 a {
  color: #749fcd; /* Sets the link color to a blue shade */
  text-decoration: none; /* Optional: removes underline */
}

.banner-heading1 a:hover {
  color: #749fcd; /* Darker blue on hover */
}

.banner-heading1 a:visited {
  color: #749fcd; /* Keeps the color the same even after the link has been visited */
}

.contact-button1 {
  display: block;
  width: 150px; /* Adjusted width */
  height: 50px; /* Adjusted height */
  margin: 20px 0 0; /* Adjusted margin */
  background-color: #263545;
  color: #fff;
  text-align: center;
  line-height: 50px;
  border-radius: 10px; /* Make the button less round */
  transition: background-color 0.3s ease; /* Add transition for hover effect */

  font-weight: bold; /* Make the text bold */
  text-transform: none; /* Remove text uppercase transformation */
  text-decoration: none; /* Remove underline */
  transition: all 0.3s ease-in-out;
  float: left; /* Align the button to the left */
  margin-left: 20px; /* Adjusted margin */
}

.contact-button1:hover {
  background-color: #1a2433;
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(26, 36, 51, 0.2);
  cursor: pointer; /* Change cursor to pointer */
}

/*Contactform*/
.contact-form-container {
  width: 50%; /* Reduce the width of the form */
  margin-right: 0; /* Align the form to the right */
  margin-left: auto; /* Align the form to the right */
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 8px;
  margin-top: 50px;
  margin-bottom: 50px;
}

.form-heading {
  text-align: center;
  color: #333;
}

.contact-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-control {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.agree-to-terms {
  display: flex; /* Align the checkbox and the text in a row */
  align-items: center; /* Vertically center the checkbox and the text */
  margin-bottom: 20px; /* Add some space below the text */
  font-size: 12px; /* Reduce the font size */
}

.agree-to-terms input[type="checkbox"] {
  margin-right: 10px; /* Add some space between the checkbox and the text */
}

.send-button {
  width: 80px; /* Reduce the width of the button */
  height: 40px; /* Reduce the height of the button */
  font-size: 12px; /* Reduce the font size */
}
.contact-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.contact-form {
  max-width: 600px; /* The form will not be wider than 600px */
  width: 100%; /* The form will take up the full width of its parent, up to the max-width */
  margin: 0 auto; /* This will center the form */
}

.contact-form .input-field {
  flex: 0 0 48%; /* Make input fields take up 48% of the width of the form */
  margin-bottom: 20px;
}

.contact-form .full-width {
  flex: 0 0 100%; /* Make certain fields take up the full width of the form */
}
.contact-form button[type="submit"] {
  padding: 15px 45px;

  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1.2px; /* Increased spacing for readability in uppercase */
  color: #ffffff;
  background-color: #2c3e50; /* Theme color for the button */
  border: none;
  border-radius: 50px; /* Larger border radius for pill-shaped button */
  transition: all 0.3s ease-in-out;
  box-shadow: 0 5px 20px rgba(44, 62, 80, 0.15); /* Box-shadow for a 3D look */
}

.contact-form button[type="submit"]:hover {
  background-color: #1a2433;
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(26, 36, 51, 0.2);
  cursor: pointer; /* Change cursor to pointer */
}

@keyframes shake {
  0% {
    transform: translate(0.5px, 0.5px) rotate(0deg);
  }
  10% {
    transform: translate(-0.5px, -1px) rotate(-0.5deg);
  }
  20% {
    transform: translate(-1.5px, 0px) rotate(0.5deg);
  }
  30% {
    transform: translate(1.5px, 1px) rotate(0deg);
  }
  40% {
    transform: translate(0.5px, -0.5px) rotate(0.5deg);
  }
  50% {
    transform: translate(-0.5px, 1px) rotate(-0.5deg);
  }
  60% {
    transform: translate(-1.5px, 0.5px) rotate(0deg);
  }
  70% {
    transform: translate(1.5px, 0.5px) rotate(-0.5deg);
  }
  80% {
    transform: translate(-0.5px, -0.5px) rotate(0.5deg);
  }
  90% {
    transform: translate(0.5px, 1px) rotate(0deg);
  }
  100% {
    transform: translate(0.5px, -1px) rotate(-0.5deg);
  }
}

.error-message {
  color: red; /* Change the text color */
  font-weight: bold; /* Make the text bold */
  animation: shake 0.5s; /* Add shake animation */
  animation-iteration-count: 2; /* Repeat the animation */
}

.success-popup {
  position: fixed; /* Make the popup appear above other elements */
  top: 50%; /* Center the popup vertically */
  left: 50%; /* Center the popup horizontally */
  transform: translate(
    -50%,
    -50%
  ); /* Adjust the position so the popup is truly centered */
  padding: 20px;
  background-color: #dff0d8; /* Light green background */
  color: #3c763d; /* Dark green text */
  border: 1px solid #d6e9c6; /* Light green border */
  border-radius: 4px;
  z-index: 1000; /* Make sure the popup appears above other elements */
}

.checkbox-container {
  display: flex; /* This will place the children side by side */
  align-items: center; /* This will vertically center the children */
}

.checkbox-container input[type="checkbox"] {
  margin-right: 10px; /* Add some space between the checkbox and the label */
}

.checkbox-container input[type="checkbox"] {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.checkbox-container label {
  margin-left: 8px;
}

.checkbox-container input[type="checkbox"]:focus {
  outline: 2px solid #0056b3;
}

.checkbox-container label:hover,
.checkbox-container label:focus {
  color: #0056b3;
  text-decoration: underline;
  cursor: pointer;
}

/*Information section*/

.information-section {
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 8px;
  margin-bottom: 20px;
  margin-top: 50px;
}

.section-heading {
  text-align: center;
  color: #34495e;
}

.section-content {
  margin-top: 20px;
  line-height: 1.6;
  max-width: 75%; /* Limit the width of the text */
  margin-left: auto; /* Center the text */
  margin-right: auto; /* Center the text */
}

.contact-button {
  display: block;
  width: 120px; /* Increase the width to make it bigger */
  height: 50px; /* Increase the height to make it bigger */
  margin: 20px auto 0;
  background-color: #263545;
  color: #fff;
  text-align: center;
  line-height: 50px; /* Adjust the line height to vertically center the text */
  border-radius: 10px; /* Make the button less round */
  transition: background-color 0.3s ease; /* Add transition for hover effect */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3); /* Add box shadow for depth */
  font-weight: bold; /* Make the text bold */
  text-transform: none; /* Remove text uppercase transformation */
  text-decoration: none; /* Remove underline */
  transition: all 0.3s ease-in-out;
}

.contact-button:hover {
  background-color: #1a2433;
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(26, 36, 51, 0.2);
  cursor: pointer; /* Change cursor to pointer */
}

.info-contact-container {
  display: flex; /* This will place the children side by side */
  justify-content: space-around; /* This will add equal space around the children */
  background-color: #f8f8f8;
}

.info-contact-container > * {
  flex-basis: 55%; /* This will make each child take up 45% of the width of the container */
}

/* InfoSection */

.featuresSection {
  /* Additional styles if needed */
  height: 280px; /* Example height, adjust as needed */
  background-color: rgba(255, 0, 0, 0.02);
}

.featureContent {
  margin-top: 60px;
  max-width: 1150px; /* Set the maximum width for the content */
  width: 100%; /* Use full width up to the max-width */
}

.featureTitle {
  /* Style your h1 title here */
  font-size: 34px; /* Example font-size, adjust as needed */
  margin-bottom: 60px; /* Space below the title */
  color: #0f381a;
  position: relative;
}

.featureTitle::after {
  content: "";
  position: absolute;
  width: 7.5%;
  left: 50%;
  transform: translateX(-50%);
  bottom: -30px; /* Adjust this value to move the underline closer or further from the text */
  height: 3px; /* Adjust the thickness of the underline */
  background-color: #ff1414;
}

.featureText {
  /* Style your paragraph text here */
  margin-top: 0px;
  line-height: 1.4;
  color: #0f381a;
  font-size: 17.5px;
}

.statsSection {
  background-color: rgba(255, 0, 0, 0.02);
  padding-bottom: 50px;
}

.statsNumber {
  font-size: 4.75rem; /* Large font size for the numbers, adjust as needed */
  color: #0f381a; /* Adjust to the exact color you need */
}

.statsText {
  /* Style for the description text under the numbers */
  color: #0f381a; /* Adjust as needed to get the exact shade of white or light color */
  font-size: 20px;
  margin-top: 20px;
}

.stats-container {
  display: flex; /* This will place the children side by side */
  flex-direction: column; /* This will stack the children vertically */
  align-items: center; /* This will horizontally center the children */
}

/*ServicesSectionen*/

.servicesSection {
  background-color: #f7f7f7;
  padding-top: 1px;
  overflow: visible;
}

.servicesSection2 {
  max-width: 1600px;
  margin: 0 auto; /* Add this line to center the element horizontally */
  overflow: visible;
}

/* Grid Layout */
.servicesGrid {
  display: flex;
  justify-content: center; /* Align items to the start */
  padding: 40px;
  margin: 0 auto;
  width: auto;
  overflow-x: auto; /* Add horizontal scroll if needed */
  overflow: visible;
  z-index: 9;
}

.slideItem {
  margin-right: 20px; /* Fixed margin between items */
}

/* Service Item Base Styles */
.serviceLink {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* You can set this to whatever you need */
  width: 350px; /* Fixed width */
  text-decoration: none;
}

.value-item .back {
  text-align: center;
  position: relative;
  background: linear-gradient(45deg, #192c3b, #556a7c);
  border-radius: 15px;
  transition: all 0.2s ease;
  height: 270px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  overflow: visible;
  width: 300px; /* Fixed width */
}

.value-item-link {
  text-align: center;
  position: relative;
  border: 1px solid #777676;
  background: linear-gradient(165deg, #192c3b, #6e8aa3);
  border-radius: 15px;
  transition: all 0.3s ease;
  height: 270px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  overflow: visible;
  width: 300px; /* Fixed width */
}

.value-item .front,
.value-item .back {
  width: 300px;
  backface-visibility: hidden; /* Can be removed if you're not using 3D transforms */
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.1s linear; /* Transition for opacity */
  display: flex;
  justify-content: flex-start; /* Align items to the top */
  align-items: flex-start; /* Align items to the left */
  flex-direction: column;
  height: 100%;
  border-radius: 15px;
  cursor: pointer;
  padding: 20px; /* Adjust padding to suit your layout */
  box-sizing: border-box; /* Ensure padding is included in the element's total width and height */
  overflow: visible;
}

.value-item .back {
  opacity: 0; /* Start invisible */
}

.value-item:hover .front {
  opacity: 0; /* Hide front on hover */
}

.value-item:hover .back {
  opacity: 1; /* Show back on hover */
}

.value-item h3 {
  font-size: 24px;
  margin-bottom: 25px;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  letter-spacing: 1px;
  margin: 0; /* Remove any default margins */
  align-self: flex-start; /* Align to the start of the flex container */
  padding: 10px; /* Add padding for spacing */
  border-radius: 5px; /* Optional: Add border-radius for smoother edges */
  margin-left: 10px;
}

.value-item p {
  font-size: 16px;
  line-height: 1.85;
  opacity: 0.92;
  padding: 10px; /* Add padding for spacing */
  border-radius: 5px; /* Optional: Add border-radius for smoother edges */
  margin: 0; /* Remove any default margins */
  padding: 10px;
}

.learnMore {
  display: flex;
  align-items: center;
  margin-top: auto; /* Push to the bottom */
  padding: 10px;
  font-size: 18px;
  color: #000; /* Black color, adjust if necessary */
  text-decoration: none;
  opacity: 1; /* Ensure it's visible initially */
  transition: opacity 0.3s ease; /* Smooth transition for opacity */
  color: #ffff;
  margin-left: 10px;
}

.arrow {
  margin-left: 5px;
  transition: transform 0.3s ease;
  color: #ffffff;
}

.value-item-link:hover .arrow {
  transform: translateX(10px); /* Move arrow slightly on hover */
  opacity: 1;
}

.icon {
  font-size: 80px;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

@media (max-width: 1270px) {
  .servicesGrid {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two equal columns */
    gap: 20px; /* Consistent gap between items */
    justify-content: center; /* Center the grid items horizontally */
    width: fit-content; /* Fit the content width */
    margin: 0 auto; /* Center the grid container */
  }

  .slideItem {
    margin: 0; /* Remove any margin */
    display: flex;
    justify-content: center; /* Center the content inside each grid item */
  }

  .value-item-link {
    width: 300px; /* Fixed width for each item */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
  }
}

@media (max-width: 768px) {
  .servicesGrid {
    grid-template-columns: 1fr; /* Single column */
    gap: 20px; /* Consistent gap between items */
    width: 100%; /* Full width for the grid container */
    padding: 40px; /* Optional: Add padding to the container if needed */
  }

  .slideItem {
    margin: 0 auto; /* Center the grid items */
    width: 100%; /* Full width for each item */
  }
}

/*Timeline*/

.timelineContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color: #f7f7f7;
  letter-spacing: 1.2px;
  font-family: "Montserrat", Arial, sans-serif;
  padding-top: 100px;
}

.details {
  display: flex;
  align-items: center;
  margin-bottom: 20px; /* Add margin to separate the details from the timeline */
  text-align: left; /* Align text to the left */
}

.details h2 {
  margin-bottom: 10px;
}

.timeline {
  display: flex;
  justify-content: space-between;
  width: 80%; /* Adjust as necessary */
  overflow-x: auto;
  padding: 20px 0;
  box-sizing: border-box;
  position: relative;
}

.timelineStep {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  flex: 1;
  padding: 10px 0;
}

.timelineStep h3 {
  margin: 10px 0 0;
  color: rgb(163, 163, 163);
  transition: color 0.3s ease;
  letter-spacing: 1.2px;
  font-family: "Montserrat", Arial, sans-serif;
}

.undercircletitle.active {
  color: #192c3b;
}

.timelineStep::after {
  content: "";
  position: absolute;
  top: 20%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%);
  width: 100%; /* Span the entire width of the step */
  height: 1px;
  background-color: #192c3b;
  z-index: 1;
}

.timelineStep:first-child::after {
  left: 50%; /* Adjust to span half of the first step */
  transform: translateX(0%);
}

.timelineStep:last-child::after {
  left: 0;
  width: 100%; /* Adjust to span half of the last step */
}

.timelineContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.circle {
  width: 20px;
  height: 20px;
  border: 1px solid #192c3b;
  border-radius: 50%;
  background-color: white;
  margin-bottom: 10px;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

.circle.active {
  background: linear-gradient(135deg, #192c3b, #343e46);
}

.stepImage {
  width: 360px; /* Adjust as necessary */
  height: 360px; /* Adjust as necessary */
  margin-right: 10px;
  background-size: cover;
  background-position: center;
  border-radius: 16px;
}

.textContainer {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin-left: 20px;
  background: linear-gradient(135deg, #192c3b, #8ea2b1);
  border-radius: 16px;
  padding: 20px;
  color: white;
}

.timelineContainer h1 {
  margin-bottom: 30px;
  color: #2c3e50;
  font-weight: bold;
  font-family: "Montserrat", Arial, sans-serif;
}

@media (max-width: 890px) {
  .timelineStep h3 {
    visibility: hidden;
  }

  .stepImage {
    display: none;
  }

  .timeline {
    width: 100%;
  }
}

/*Service Highlight section*/

.serviceHighlightsSection {
  display: flex;
  align-items: center; /* Updated to 'center' to vertically align the image */
  justify-content: center; /* Updated to 'center' to horizontally align the items */
  height: 600px;
  gap: 40px;
  background: linear-gradient(135deg, #192c3b, #788fa3);
}

.serviceImage img {
  /* No changes here as per your request */
  display: block;
  width: 500px;
  height: 500px;
  object-fit: cover;
  border-radius: 4px;

  object-fit: contain;
}

.serviceContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 650px; /* Adjust the max-width as per your design */
  text-align: left;
  padding: 0;
  margin-top: 10px;
}

.about {
  font-size: 16px;
  font-weight: 600;
  color: #ff1414;
}

.serviceContent p {
  margin-bottom: 10px; /* Adjust spacing as needed */
  max-width: 300px;
}

.serviceHeading {
  font-size: 34px;
  font-weight: 600;
  color: #ffffff;
  max-width: 400px;
  margin-bottom: 20px; /* Adjust spacing as needed */
  margin-top: 10px;
}

.serviceParagraph {
  font-size: 16px;
  margin-bottom: 10px;
  max-width: 200px;
  color: #ffffff;
}

.serviceItem1 {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.serviceItem1 > * {
  margin: 0; /* Resets margin for direct children */
}

.serviceIcon1 {
  font-size: 36px;
  margin-right: 40px;
  color: #ffffff;
}

.itemtexts {
  flex-direction: column; /* Stack children vertically */
  justify-content: center; /* Center children vertically */
  margin-top: 20px;
  color: #ffffff;
}

@media (max-width: 868px) {
  /* Adjust this breakpoint as needed */
  .serviceImage {
    display: none; /* Hides the image container on smaller screens */
  }

  .serviceHeading {
    text-align: center; /* Centers the text for these elements on smaller screens */
  }
  .serviceParagraph {
    display: none;
  }
  .serviceIcon1 {
    margin: 10px;
  }

  /*Innan footern*/
  .info-contact-container {
    flex-direction: column;
  }

  .contact-form-container {
    width: 100%; /* Makes the container take full width */
    margin: 20px 0; /* Adjust margin for smaller screens */
    padding: 20px;
  }

  .contact-form {
    width: 100%; /* Ensure the form also takes up 100% width */
    max-width: 100%; /* Override any smaller max-width settings */
  }

  .contact-form .input-field,
  .contact-form .full-width {
    flex: 0 0 100%; /* Make all input fields take full width */
  }
  .contact-button {
    display: none;
  }

  /*top bannern*/
  .top-banner {
    background-color: transparent;
    background-attachment: scroll;
    background-image: contain;
    width: auto;
  }

  .banner-heading1 {
    max-width: 95%;
    font-size: 18px;
  }

  .heading-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Ensure that items are centered regardless of the flex direction */
    margin-bottom: 20px;
    /* Center-align the text for aesthetics */
  }
}

@media (max-width: 1090px) {
  .contact-button1 {
    /* Adjust width if necessary to fit content */
    margin: 0 auto; /* Adds auto margins on both sides to help in centering */
    margin-bottom: 10px;
    display: none;
  }
}
