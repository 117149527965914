/* Banner.module.css */

/* Banner1*/
/*Top banner*/
.top-banner {
  height: 630px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center; /* Center the content horizontally */
  overflow: hidden;
  z-index: 1;
  background-image: url("assets/images/mirrored_bannerimg.webp");
  background-size: cover; /* Cover the entire element */
  background-repeat: no-repeat; /* Do not repeat the image */
  padding-top: 80px; /* Adjusted for the header height */
  background-attachment: fixed; /* Make the background fixed during scroll */
}

.top-banner::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(135deg, #192c3b, #788fa3);
  opacity: 0.92; /* Adjust the opacity for desired transparency */
  z-index: 2;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the container */
  justify-content: center; /* Center content vertically */
  width: 100%;
  height: 100%;
  z-index: 5;
}

.heading-container {
  flex: 0 0 auto;
  color: white;
  z-index: 1;
  padding: 80px;
  width: 100%; /* Fixed width for the text container */
  max-width: 1465px; /* Optional max-width for larger screens */
  text-align: left; /* Left-align the text */
}

.banner-heading {
  font-size: 52px;
  margin-bottom: 30px;
}

.banner-heading1 {
  font-size: 22px;
  margin-bottom: 40px;
  font-weight: 500;
  max-width: 600px;
}

.arrow-container {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
}

.arrow-down {
  animation: bounce 1s infinite;
  color: white; /* or any other color you prefer */
  font-size: 30px; /* Adjust size as needed */
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

/*Our values section*/
.values-section {
  padding: 90px 50px;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffffff;
  font-family: "Helvetica Neue", sans-serif;
}

.values-title {
  font-size: 50px;
  font-weight: 800;
  margin-bottom: 70px;
  color: #2c3e50;
  text-align: center;
  text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.1);
  letter-spacing: 1.5px;
  border-bottom: 3px solid #34495e;
  padding-bottom: 10px;
}

.values-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 1300px;
  gap: 80px;
}

.value-item {
  flex: 1;
  max-width: 400px;
  background-color: #3b4c63;
  padding: 45px;
  box-shadow: 0 8px 26px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  border: 2px solid rgba(255, 255, 255, 0.05);
  text-align: center;
  position: relative;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.value-item .front,
.value-item .back {
  width: 100%;
  backface-visibility: hidden; /* Can be removed if you're not using 3D transforms */
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.1s linear; /* Transition for opacity */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  border-radius: 15px;
  cursor: pointer;
}

.value-item .back {
  opacity: 0; /* Start invisible */
}

.value-item:hover .front {
  opacity: 0; /* Hide front on hover */
}

.value-item:hover .back {
  opacity: 1; /* Show back on hover */
}

.value-item:hover {
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.25);
  border: 2px solid rgba(255, 255, 255, 0.1);
}

.value-item h3 {
  font-size: 34px;
  margin-bottom: 25px;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  letter-spacing: 1px;
}

.value-item p {
  font-size: 19px;
  line-height: 1.85;
  opacity: 0.92;
  padding: 10px;
}

.hoverIcon {
  color: #fff;
  font-size: 24px;
  margin-top: 20px;
  transition: opacity 0.3s ease; /* Transition only for opacity */
}

.value-item:hover .hoverIcon {
  opacity: 1; /* Fully opaque on hover */
}

.icon-style {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 30px;
}

.title-container {
  position: relative;
  display: flex;
  align-items: center;
}

.circle2 {
  position: absolute;
  width: 35px;
  height: 35px;
  background-color: rgb(36, 86, 160);
  border-radius: 50%;

  /* Adjust these values */
  top: 60px;

  transform: translateY(-50%);
}

.values-title {
  /* Your existing styles for values-title */
  position: relative; /* Needed so z-index works properly */
}

/*Steps delen*/
.contact-container {
  padding: 175px;
  display: flex; /* Activate Flexbox */
  flex-direction: column; /* Set the flex direction to column */
  justify-content: center; /* Vertically align the content in the center */
  align-items: center; /* Horizontally align the content in the center */
  position: relative;
  max-width: 1550px;
  margin: 0 auto;
  overflow: hidden;
}

.contact-container2 {
  margin: 0 auto;
  background-color: #2c3e50;
}

.contact-title {
  font-size: 45px;
  text-align: center;
  margin-bottom: 120px;
  color: white;
  font-weight: 800;
  letter-spacing: 0.8px;
  border-bottom: 3px solid #3e5770;
}

.step-wrapper {
  display: flex;
  align-items: center;

  max-width: 1420px;
}

.step-wrapper:nth-child(odd) {
  flex-direction: row-reverse;
  margin-right: -300px;
}
.step-wrapper:nth-child(even) {
  margin-left: -300px;
}

.step-number {
  font-size: 170px;
  color: white;
  margin-right: 20px;
  line-height: 1;
  position: relative;
  top: -190px;
  z-index: 3;
}

.step-wrapper:nth-child(odd) .step-number {
  left: -480px;
}

.step-wrapper:nth-child(even) .step-number {
  right: -10px;
}

.text-wrapper {
  flex: 1;
  max-width: 900px;
  width: auto;
}

.smaller-title {
  font-size: 40px;
  text-align: left;
  margin-bottom: 10px;
  color: white;
}

.description-text {
  color: white;
}

.image-container {
  width: 350px;
  height: 450px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.step-wrapper:nth-child(odd) .image-container {
  left: 20px;
  margin-right: 90px;
}

.step-wrapper:nth-child(even) .image-container {
  left: 80px;
  margin-left: 125px; /* Adjusting the gap between the image and the text for odd-numbered steps */
}

.image-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.icon-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
  z-index: 0;
}

.icon-step-container {
  display: flex;
  align-items: center; /* Vertically center child items */
  justify-content: center; /* Horizontally center child items */
  position: relative;
}

/*Sektionen innan footern*/
.new-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 85px;
  background-color: #f7f7f7; /* Neutral light grey background to reduce starkness of pure white */
}

.content-section {
  width: 100%;
  text-align: center;
  padding: 3rem;
  background-color: #ffffff; /* Pure white content box */
  border-radius: 10px;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.05); /* Soft shadow for depth */
  max-width: 1300px;
}

.content-section h2 {
  font-size: 42px;
  color: #2c3e50; /* Dark color for contrast */
  font-weight: 800; /* Strong font weight for titles */
  margin-bottom: 2rem;
  line-height: 1.2;
}

.content-section p {
  font-size: 20px;
  line-height: 1.7;
  color: #555555; /* Neutral readable color */
  margin: 0 auto 2.5rem; /* Space before the button */
  max-width: 800px;
  font-weight: 300; /* Lighter font-weight to contrast the title */
}

.content-section-button {
  padding: 15px 45px;
  font-family: "Montserrat", Arial, sans-serif;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1.2px; /* Increased spacing for readability in uppercase */
  color: #ffffff;
  background-color: #2c3e50; /* Theme color for the button */
  border: none;
  border-radius: 50px; /* Larger border radius for pill-shaped button */
  transition: all 0.3s ease-in-out;
  box-shadow: 0 5px 20px rgba(44, 62, 80, 0.15); /* Box-shadow for a 3D look */
}

.content-section-button:hover {
  background-color: #1a2433;
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(26, 36, 51, 0.2);
}

.content-section-button:focus {
  outline: none;
}

.content-section-button:active {
  transform: translateY(2px);
  box-shadow: 0 3px 10px rgba(26, 36, 51, 0.2);
}

/*FAQ sektionen*/
.faq-section {
  padding: 85px;
  text-align: center;
  background-color: #f7f7f7;
  margin-bottom: -80px;
}

.faq-section h2 {
  font-size: 30px;
  color: #243e54;
  margin-bottom: 20px;
  font-weight: bold;
}

.faq-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  max-width: 1300px;
  margin: 0 auto;
}

.faq-item {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s;
  position: relative;
  box-sizing: border-box;
  background: white;
}

.faq-item:hover {
  background-color: #f6f6f6;
}

.faq-question {
  margin: 0;
  color: #2c3e50;
  font-size: 18px;
}

.faq-answer {
  margin-top: 10px;
  text-align: left;
  z-index: 299;
}

.icon-indicator {
  position: absolute;
  top: 10px;
  right: 10px;
  transition: transform 0.3s;
  color: #192c3b;
}

@media screen and (max-width: 1000px) {
  /*Top banner*/
  .banner-heading {
    font-size: 35px;
    margin-top: -50px;
  }
  .banner-heading1 {
    font-size: 18px;
    width: 100%;
    max-width: 100%;
  }
  .content-wrapper {
    margin-left: -50px;
  }

  /*Our Values*/

  .values-section {
    padding: 60px 20px;
  }

  .values-title {
    font-size: 32px;
    margin-bottom: 40px;
  }

  .values-container {
    flex-direction: column;
    gap: 40px;
    align-items: center;
  }

  .value-item {
    max-width: 100%;
    padding: 30px;
    width: 400px;
    min-height: 300px;
  }

  .value-item h3 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .value-item p {
    font-size: 16px;
  }

  /*ContactStep*/

  .contact-container {
    padding: 55px;
  }

  .contact-title {
    font-size: 30px;
    margin-bottom: 60px;
  }

  .step-wrapper {
    flex-direction: column; /* Stack elements vertically */
    align-items: center; /* Center items horizontally */
    width: 100%;
  }

  .step-wrapper:nth-child(odd),
  .step-wrapper:nth-child(even) {
    flex-direction: column; /* Override previous styles and stack vertically */
    margin-left: 0;
    margin-right: 0;
    left: 0;
  }

  .step-wrapper:nth-child(odd) .step-number {
    left: 110px;
  }

  .step-wrapper:nth-child(even) .step-number {
    left: 110px;
  }

  .step-number {
    top: 40px;
  }

  .image-container,
  .text-wrapper {
    order: 2; /* Place text-wrapper below */
    margin: 0; /* Reset any specific margins */
    left: 0; /* Reset */
    width: 100%; /* Decrease width for better fitting */
    text-align: center;
  }

  .text-wrapper {
    order: 1; /* Place text-wrapper above */
    text-align: left;
    margin-bottom: 20px;
  }
  .image-container {
    display: none;
  }

  .icon-image {
    width: 100%;
    height: auto;
  }

  .smaller-title {
    font-size: 28px;
  }

  .faq-grid {
    grid-template-columns: 1fr; /* Makes it a single column layout */
    gap: 0px; /* Adjust gap if needed for mobile */
  }

  .faq-section h2 {
    font-size: 24px; /* Adjust title size for mobile if desired */
  }
  .faq-section {
    padding: 50px;
  }

  .new-section {
    padding: 50px;
    margin-top: 30px;
  }

  .faq-item {
    margin-bottom: 10px; /* Adjust margin for mobile if desired */
    max-width: 100%; /* Adjust the width of the questions for mobile */
    margin-left: auto;
    margin-right: auto;
  }
  .icon-indicator {
    margin-left: 10px;
  }
  .faq-question {
    width: calc(
      100% - 30px
    ); /* Adjust based on how the layout looks on mobile, reducing space for the icon */
  }

  .content-section {
    width: 100%; /* Increase width on smaller screens */
    padding: 1.5rem; /* Less padding for more compact look */
  }

  .content-section h2 {
    font-size: 30px; /* Slightly smaller font-size for headers */
    margin-bottom: 1.5rem; /* Adjust margin for a more compact look */
  }

  .content-section p {
    font-size: 18px; /* Adjusting the paragraph font size for mobile screens */
    margin: 0 auto 1.5rem; /* Adjust margin for a more compact look */
  }

  .content-section-button {
    padding: 10px 30px; /* Adjusting padding for the button */
    font-size: 16px; /* Adjusting font size for the button */
  }

  .top-banner {
    background-color: transparent;
    background-attachment: scroll;
    background-image: contain;
    width: auto;
  }
}
