.contentContainer {
  background-color: #f7f7f7;
}
/*Top banner*/
.top-banner {
  height: 630px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 1;
  background-image: url("assets/images/mirrored_bannerimg.webp");
  background-size: cover; /* Cover the entire element */
  background-repeat: no-repeat; /* Do not repeat the image */
  padding-top: 80px; /* Adjusted for the header height */
  background-attachment: fixed; /* Make the background fixed during scroll */
}

.top-banner::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(135deg, #192c3b, #788fa3);
  opacity: 0.92; /* Adjust the opacity for desired transparency */
  z-index: 2;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the container */
  justify-content: center; /* Center content vertically */
  width: 100%;
  height: 100%;
  align-items: center; /* Center the container */
  justify-content: center; /* Center content vertically */
  z-index: 5;
}

.heading-container {
  flex: 0 0 auto;
  color: white;
  z-index: 1;
  padding: 80px;
  width: 100%;
  max-width: 1465px;
  text-align: left;
}

.banner-heading {
  font-size: 52px;
  margin-bottom: 30px;
}

.banner-heading1 {
  font-size: 22px;
  margin-bottom: 40px;
  font-weight: 500;
  max-width: 600px;
}

.arrow-container {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
}

.arrow-down {
  animation: bounce 1s infinite;
  color: white; /* or any other color you prefer */
  font-size: 30px; /* Adjust size as needed */
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

/*AI EXPLAIN*/

.explain-section {
  padding-top: 70px;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: row;
  color: #ffffff;
  padding-left: 80px;
  padding-bottom: 90px;
  align-items: center;
  padding-right: 40px;
  max-width: 1450px;
  margin: 0 auto;
}

.content-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.text-container {
  max-width: 600px;
}

.explainTitle {
  font-size: 42px;
  font-weight: bold;
  padding-bottom: 10px;
  letter-spacing: 1px;
  text-align: left;
  color: #34495e;
}

.explainText {
  font-size: 18px;
  font-weight: 350;
  max-width: 770px;
  padding-top: 5px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #34495e;
}

.explainText:last-of-type {
  margin-bottom: 45px;
}

.growthButton {
  padding: 15px 45px;
  font-family: "Montserrat", Arial, sans-serif;
  margin: 0 auto;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1.2px;
  color: #ffffff;
  background-color: #2c3e50;
  border: none;
  border-radius: 50px;
  transition: all 0.3s ease;
  box-shadow: 0 5px 20px rgba(44, 62, 80, 0.15);
  text-decoration: none;
  z-index: 999999;
}

.growthButton:hover {
  background-color: #1a2433;
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(26, 36, 51, 0.2);
  color: #ffffff;
}

.icons-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end; /* Aligns items inside the container to the right */
  align-items: center;

  margin-left: auto; /* Pushes the entire container to the right of the parent */
  padding-left: 40px;
  margin-right: 40px;
}

.icon {
  font-size: 65px;
  color: #34495e;
}

.icon-with-title {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon-title {
  font-size: 18px; /* Justera efter behov */
  color: #34495e; /* Anpassa efter din design */
  margin-top: 10px;
}

.arrow {
  font-size: 26px;
  color: #34495e;
  margin-right: 40px;
  margin-left: 40px;
  margin-bottom: 25px;
  z-index: 999999;
}

/*Info 3*/

.info3-container {
  background-color: #ffffff;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: visible;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.info3-content {
  max-width: 700px;
  text-align: center;
}

.info3-title {
  color: #34495e;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1px;
  margin-bottom: 20px;
}

.info3-text {
  font-size: 42px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 1.25;
  margin-bottom: 40px;
  color: #34495e;
}

.underline {
  position: relative;
  display: inline-block;
}

.underline::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 4px; /* Tjockleken på understrykningen */
  background: linear-gradient(45deg, #192c3b, #556a7c);
  text-underline-offset: 4px; /* Justerar avståndet mellan texten och understrykningen */
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.6s ease;
}

.animate-underline::after {
  transform: scaleX(1);
}

.info3-button {
  padding: 15px 45px;
  font-family: "Montserrat", Arial, sans-serif;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1.2px;
  color: #ffffff;
  background-color: #2c3e50;
  border: none;
  border-radius: 50px;
  transition: transform 0.3s ease, background-color 0.3s ease,
    box-shadow 0.3s ease;
  box-shadow: 0 5px 20px rgba(44, 62, 80, 0.15);
  text-decoration: none;
  z-index: 10;
}

.info3-button:hover {
  background-color: #1a2433;
  transform: translateY(-3px) !important;
  box-shadow: 0 5px 15px rgba(26, 36, 51, 0.2);
  color: #ffffff;
}

.info3-button:focus {
  outline: none;
}

.info3-button:active {
  transform: translateY(2px);
  box-shadow: 0 3px 10px rgba(26, 36, 51, 0.2);
}

/*Our values*/

.values-section {
  padding: 80px 50px;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Helvetica Neue", sans-serif;
}

.values-title {
  font-size: 42px;
  font-weight: 800;
  margin-bottom: 50px;
  text-align: center;
  color: #34495e;
}

.values-circle {
  position: relative;
  width: 1100px; /* Increased width to allow larger value items */
  height: 670px; /* Adjusted height for better alignment */
}

.value-item {
  position: absolute;
  width: 300px; /* Increased size by 100px */
  height: auto;
  background-color: transparent;
  padding: 25px;
  border-radius: 15px;
  text-align: center;

  transition: all 0.4s ease;

  color: #254157;
}

.value-list {
  list-style-type: none; /* Remove default bullet points */
  padding: 0; /* Remove default padding */
  margin: 0;
  width: 100%; /* Ensure the list takes up the full width */
}

.value-list li {
  display: flex;
  align-items: flex-start; /* Align items to the top of the li */
  font-size: 18px;
  margin-bottom: 18px; /* Space between list items */
  justify-content: flex-start; /* Align the icon and text to the start */
  width: 100%;
  line-height: 1.5;
  position: relative;
  padding-left: 30px; /* Space for the icon */
  text-align: left; /* Ensure text is left-aligned */
  word-wrap: break-word; /* Allows text to wrap to the next line if necessary */
}

.checkmark-icon {
  font-size: 24px; /* Set the size of the icon */
  color: #34495e; /* Set the color of the icon */
  position: absolute;
  left: 0; /* Align to the left within the li */
  top: 2px; /* Align to the top within the li */
}

.value-item h3 {
  margin-bottom: 20px;
}

.item-1 {
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
}
.item-2 {
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
}
.item-3 {
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
}
.item-4 {
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
}

/* Arrows using before pseudo-element */
.item-1::before,
.item-2::before,
.item-3::before,
.item-4::before {
  content: "";
  position: absolute;
  width: 220px;
  height: 220px;
  border: 3px solid #34495e;
  border-radius: 60%;
}

.item-1::before {
  top: 55px;
  left: -150px;
  transform: translateX(-50%) rotate(45deg);
  border-color: transparent transparent transparent #34495e;
}

.item-2::before {
  top: -5px;
  right: 150px;
  transform: translateY(-50%) rotate(135deg);
  border-color: transparent transparent transparent #34495e;
}

.item-3::before {
  bottom: -42px;
  left: 340px;
  transform: translateY(-50%) rotate(-135deg);
  border-color: transparent transparent transparent #34495e;
}

.item-4::before {
  bottom: -230px;
  left: 150px;
  transform: translateY(-50%) rotate(-45deg);
  border-color: transparent transparent transparent #34495e;
  border-radius: 80%;
}

/* Lines connecting the circles */
.item-1::after,
.item-2::after,
.item-3::after,
.item-4::after {
  content: "";
  position: absolute;
  width: 140px; /* Adjust the length of the line */
  height: 3px;
  background-color: #34495e;
}

.item-1::after {
  top: 55px;
  left: 0px;
  transform: translateX(-110%) rotate(0deg); /* Line from item 1 to item 2 */
}

.item-2::after {
  top: -114px;
  right: 260px;
  transform: translateY(-40%) rotate(0deg); /* Line from item 2 to item 3 */
}

.item-3::after {
  bottom: 68px;
  left: 390px;
  transform: translateX(-50%) rotate(180deg); /* Line from item 3 to item 4 */
}

.item-4::after {
  bottom: -120px;
  left: 330px;
  transform: translateX(-50%) rotate(180deg); /* Line from item 3 to item 4 */
}

/* Arrowheads */
.item-1-arrow::after,
.item-2-arrow::after,
.item-3-arrow::after,
.item-4-arrow::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
}

/* Arrowhead pointing to the right */
.item-1-arrow::after {
  top: 48px;
  left: -10px; /* Change from left to right */
  transform: translateX(-50%); /* Invert the translate direction */
  border-width: 8px 0 8px 12px; /* Adjust the border width */
  border-color: transparent transparent transparent #34495e; /* Adjust the border color */
}

/* Arrowhead pointing down */
.item-2-arrow::after {
  top: -8px;
  left: 140px;
  transform: translateY(-50%);
  border-width: 12px 8px 0 8px;
  border-color: #34495e transparent transparent transparent;
}

/* Arrowhead pointing to the left */
.item-3-arrow::after {
  top: 238.5px;
  left: 314px;
  transform: translateX(-50%);
  border-width: 8px 12px 8px 0;
  border-color: transparent #34495e transparent transparent;
}

/* Arrowhead pointing up */
.item-4-arrow::after {
  top: 350px;
  right: 140px;
  transform: translateY(-50%);
  border-width: 0 8px 12px 8px;
  border-color: transparent transparent #34495e transparent;
}

@media only screen and (max-width: 1080px) {
  .values-section {
    padding: 40px 20px;
  }

  .values-title {
    font-size: 32px;
    margin-bottom: 30px;
  }

  .values-circle {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .value-item {
    width: 100%;
    margin-bottom: 20px;
    padding: 15px;
    position: static;
    transform: none;
  }

  .item-1,
  .item-2,
  .item-3,
  .item-4 {
    position: static;
    transform: none;
  }

  .item-1-arrow::after,
  .item-2-arrow::after,
  .item-3-arrow::after,
  .item-4-arrow::after {
    display: none;
  }

  /* Remove arrows and lines */
  .item-1::before,
  .item-2::before,
  .item-3::before,
  .item-4::before,
  .item-1::after,
  .item-2::after,
  .item-3::after,
  .item-4::after {
    display: none;
  }

  .value-list {
    text-align: left;
  }

  .checkmark-icon {
    font-size: 20px;
  }

  h3 {
    font-size: 24px;
  }

  .value-item h3 {
    margin-bottom: 15px;
  }
}

@media (max-width: 1080px) {
  .values-circle {
    width: 100%;
    height: auto;
  }

  .value-item {
    width: 250px; /* Adjusted for smaller screens */
  }

  .item-1::before,
  .item-2::before,
  .item-3::before,
  .item-4::before {
    width: 80px;
    height: 80px;
    border-width: 2px;
  }

  .item-1::after,
  .item-2::after,
  .item-3::after,
  .item-4::after {
    border-width: 7px 7px 0 7px; /* Adjust arrowhead size */
  }
}

@media (max-width: 768px) {
  .info3-container {
    height: auto; /* Allow the container to adjust to its content */
    padding: 20px; /* Add padding to the container */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .info3-content {
    max-width: 100%; /* Make sure the content spans the width of the screen */
    text-align: center;
  }

  .info3-title {
    font-size: 18px; /* Increase font size for better readability */
    margin-bottom: 15px; /* Adjust margin */
  }

  .info3-text {
    font-size: 28px; /* Reduce font size for mobile */
    line-height: 1.4; /* Slightly increase line-height for better readability */
    margin-bottom: 30px; /* Adjust margin */
    padding: 0 10px; /* Add padding to ensure the text isn't too close to the screen edges */
  }

  .underline::after {
    height: 3px; /* Adjust thickness of the underline */
  }

  .info3-button {
    padding: 10px 20px; /* Keep padding consistent */
    font-size: 16px; /* Slightly reduce the font size */
    border-radius: 25px; /* Adjust border-radius for smaller screens */
  }
}

/* AI chatbots showcase */

.showcase-section {
  background: linear-gradient(135deg, #192c3b, #788fa3);
  padding-top: 140px;
  padding-bottom: 100px;
  height: 1000px;
}

.showcase-title {
  text-align: left;
  color: white;
  font-weight: bold;
  padding-bottom: 20px;
}

.block {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  overflow: hidden;
  max-width: 1470px;
  margin: 0 auto;
  padding: 80px;
}

.video-container {
  flex: 1;
  display: block;
  justify-content: left;
  align-items: center;
  position: relative;

  width: auto;
}

.video {
  width: auto;

  max-height: 600px;
}

.content-container1 {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: transparent;

  border-radius: 16px;

  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out; /* Transition for container */
}

.description-container {
  text-align: left;
  padding-top: 30px;
  align-items: left;

  background-color: transparent;
  position: inherit;
  opacity: 1;
  max-width: 500px;
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out; /* Transition for description */
  height: 500px;
}

.chatbot-title {
  font-size: 24px;
  margin-bottom: 0.5rem;
  color: white;
}

.chatbot-description {
  list-style-type: disc;
  padding: 20px;
  color: white;
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out; /* Transition for descriptions */
}

.chatbot-description li {
  margin-bottom: 10px;
  font-size: 1rem;
  line-height: 1.5;
  list-style: none;
  color: white;
  z-index: 999999;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out; /* Transition for list items */
}

.button-container-wrapper {
  position: relative;
  max-width: 500px;
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: center; /* Horizontally center the button */
  align-items: center; /* Vertically center the button */
  background-color: transparent;
  border-radius: 64px;
  position: relative;
  border-top: 2px solid white;
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out; /* Transition for button container */
  border: 3px solid white;
  overflow: hidden;
  max-width: 300px;
  height: 100%; /* Ensure the container has a height to allow vertical centering */
}

.slider {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50%; /* Adjust based on the number of buttons */
  height: 100%;
  background-color: white; /* Transparent background */
  border: 3px solid white; /* Border around the active tab */
  border-bottom: none; /* No bottom border for active tab */

  z-index: 1; /* Ensure the slider stays above the bottom border */
  margin-top: 5px;
  transform: translateX(0); /* Ensure it starts at the correct position */
  transition: transform 0.2s ease-in-out; /* Smooth transition for the slider */
  border-radius: 64px;
}

.button {
  padding: 10px 25px;
  font-family: "Montserrat", Arial, sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: white;
  background-color: transparent;
  border: none;
  text-align: center;
  flex: 1;
  cursor: pointer;
  z-index: 2; /* Ensure the button text stays above the slider */
}

.firstButton {
  /* Styles for the first button */

  z-index: 99;

  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out; /* Transition for buttons */
}

.secondButton {
  /* Styles for the second button */
  z-index: 99;

  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out; /* Transition for buttons */
}

.active {
  background-color: transparent;
  color: #243e54;
  z-index: 3; /* Higher z-index for active button to appear above everything */
  border-bottom: 3px solid transparent; /* Ensure there's no bottom border for the active tab */
}

.play-button {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 175px;
  z-index: 99999;
  width: 50px;
  height: 50px;
  background-color: rgba(26, 36, 51, 0.7);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
}

.video-playing .play-button {
  display: none;
}

.chatbot-description {
  list-style: none;
  padding: 0;
  color: #243e54;
}

.chatbot-description li {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.point-number {
  font-size: 2rem;
  font-weight: bold;
  color: white; /* Adjust color as needed */
  margin-right: 20px;
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out; /* Transition for description */
  margin-left: -20px;
}

.point-title {
  font-size: 1.25rem;
  font-weight: bold;
  color: white;
}

.point-description {
  font-size: 1rem;
  color: white;
  margin-left: 10px;
}

/* Mobile Styles for AIShowcase */
@media (max-width: 768px) {
  .showcase-section {
    padding-top: 60px;
    padding-bottom: 60px;
    height: auto; /* Allow height to adjust based on content */
  }

  .block {
    flex-direction: column; /* Stack video and content vertically */
    padding: 20px;
    align-items: center;
  }

  .video-container {
    width: 100%;
    margin-bottom: 20px; /* Add spacing between video and content */
  }

  .video {
    width: 100%; /* Ensure video is full width */
    max-height: 500px; /* Adjust max-height for smaller screens */
  }

  .content-container1 {
    flex: unset;
    width: 100%;
    padding: 0 20px;
  }

  .showcase-title {
    text-align: center; /* Center align title for mobile */
    font-size: 1.5rem; /* Adjust font size for mobile */
  }

  .description-container {
    padding-top: 20px;
    max-width: 100%; /* Allow description to take full width */
    height: auto; /* Adjust height based on content */
  }

  .button-container {
    max-width: 100%;
    height: auto;
    flex-direction: row; /* Stack buttons vertically on mobile */
    border-radius: 32px; /* Reduce border radius for mobile */
  }

  .play-button {
    left: 50%; /* Center play button horizontally */
    width: 40px;
    height: 40px;
  }

  .chatbot-description li {
    margin-bottom: 15px; /* Adjust spacing between list items */
    flex-direction: row; /* Stack number, title, and description vertically */
    align-items: flex-start;
  }

  .point-number {
    font-size: 1.5rem; /* Adjust font size for mobile */
    margin-bottom: 10px; /* Add spacing below the number */
    margin-left: 0; /* Remove left margin */
  }

  .point-title {
    font-size: 1.25rem; /* Adjust font size for mobile */
    margin-bottom: 5px;
  }

  .point-description {
    font-size: 0.875rem; /* Adjust font size for mobile */
    margin-left: 0;
  }
}

/*Steps delen*/
.contact-container {
  padding: 175px;
  display: flex; /* Activate Flexbox */
  flex-direction: column; /* Set the flex direction to column */
  justify-content: center; /* Vertically align the content in the center */
  align-items: center; /* Horizontally align the content in the center */
  position: relative;
  max-width: 1550px;
  margin: 0 auto;
  overflow: hidden;
}

.contact-container2 {
  margin: 0 auto;
  background-color: #2c3e50;
}

.contact-title {
  font-size: 45px;
  text-align: center;
  margin-bottom: 120px;
  color: white;
  font-weight: 800;
  letter-spacing: 0.8px;
  border-bottom: 3px solid #3e5770;
}

.step-wrapper {
  display: flex;
  align-items: center;

  max-width: 1420px;
}

.step-wrapper:nth-child(odd) {
  flex-direction: row-reverse;
  margin-right: -300px;
}
.step-wrapper:nth-child(even) {
  margin-left: -300px;
}

.step-number {
  font-size: 170px;
  color: white;
  margin-right: 20px;
  line-height: 1;
  position: relative;
  top: -190px;
  z-index: 3;
}

.step-wrapper:nth-child(odd) .step-number {
  left: -480px;
}

.step-wrapper:nth-child(even) .step-number {
  right: -10px;
}

.text-wrapper {
  flex: 1;
  max-width: 900px;
  width: auto;
}

.smaller-title {
  font-size: 40px;
  text-align: left;
  margin-bottom: 10px;
  color: white;
}

.description-text {
  color: white;
}

.image-container {
  width: 350px;
  height: 450px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.step-wrapper:nth-child(odd) .image-container {
  left: 20px;
  margin-right: 90px;
}

.step-wrapper:nth-child(even) .image-container {
  left: 80px;
  margin-left: 125px; /* Adjusting the gap between the image and the text for odd-numbered steps */
}

.image-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.icon-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
  z-index: 0;
}

.icon-step-container {
  display: flex;
  align-items: center; /* Vertically center child items */
  justify-content: center; /* Horizontally center child items */
  position: relative;
}

/*Sektionen innan footern*/
.new-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 85px;
  background-color: #f7f7f7; /* Neutral light grey background to reduce starkness of pure white */
}

.content-section {
  width: 100%;
  text-align: center;
  padding: 3rem;
  background-color: #ffffff; /* Pure white content box */
  border-radius: 10px;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.05); /* Soft shadow for depth */
  max-width: 1300px;
}

.content-section h2 {
  font-size: 42px;
  color: #2c3e50; /* Dark color for contrast */
  font-weight: 800; /* Strong font weight for titles */
  margin-bottom: 2rem;
  line-height: 1.2;
}

.content-section p {
  font-size: 20px;
  line-height: 1.7;
  color: #555555; /* Neutral readable color */
  margin: 0 auto 2.5rem; /* Space before the button */
  max-width: 800px;
  font-weight: 300; /* Lighter font-weight to contrast the title */
}

.content-section-button {
  padding: 15px 45px;
  font-family: "Montserrat", Arial, sans-serif;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1.2px; /* Increased spacing for readability in uppercase */
  color: #ffffff;
  background-color: #2c3e50; /* Theme color for the button */
  border: none;
  border-radius: 50px; /* Larger border radius for pill-shaped button */
  transition: all 0.3s ease-in-out;
  box-shadow: 0 5px 20px rgba(44, 62, 80, 0.15); /* Box-shadow for a 3D look */
}

.content-section-button:hover {
  background-color: #1a2433;
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(26, 36, 51, 0.2);
}

.content-section-button:focus {
  outline: none;
}

.content-section-button:active {
  transform: translateY(2px);
  box-shadow: 0 3px 10px rgba(26, 36, 51, 0.2);
}

/*FAQ sektionen*/
.faq-section {
  padding: 85px;
  text-align: center;
  background-color: #f7f7f7;
  margin-bottom: -80px;
}

.faq-section h2 {
  font-size: 30px;
  color: #243e54;
  margin-bottom: 20px;
  font-weight: bold;
}

.faq-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  max-width: 1300px;
  margin: 0 auto;
}

.faq-item {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s;
  position: relative;
  box-sizing: border-box;
  background: white;
}

.faq-item:hover {
  background-color: #f6f6f6;
}

.faq-question {
  margin: 0;
  color: #2c3e50;
  font-size: 18px;
}

.faq-answer {
  margin-top: 10px;
  text-align: left;
  z-index: 299;
}

.icon-indicator {
  position: absolute;
  top: 10px;
  right: 10px;
  transition: transform 0.3s;
  color: #192c3b;
}

@media screen and (max-width: 910px) {
  /*Top banner*/
  .banner-heading1 {
    font-size: 1em;
    margin-top: 0.5em;
    text-align: left;
    font-weight: lighter;
    max-width: 100%;
  }
  .arrow-container {
    display: none;
  }

  .banner-heading {
    font-size: 34px; /* Adjust font size for smaller screens */
  }

  /* AI EXPLAIN */

  .explain-section {
    padding-left: 60px;
  }

  .content-container {
    flex-direction: column;
    align-items: center;
  }

  .icons-container {
    display: none;
  }

  .explainTitle {
    font-size: 32px;
    text-align: left;
  }

  .explainText {
    font-size: 16px;
    text-align: left;
  }

  .growthButton {
    font-size: 16px;
    padding: 12px 30px;
  }

  .text-container {
    width: 100%;
    max-width: 570px; /* Bevara en maxbredd för bättre läsbarhet */
    margin: 0 auto; /* Centrerar text-container horisontellt */
  }

  /*Our Values*/

  .values-section {
    padding: 60px 20px;
  }

  .values-title {
    font-size: 32px;
    margin-bottom: 40px;
  }

  .values-container {
    flex-direction: column;
    gap: 40px;
    align-items: center;
  }

  .value-item {
    max-width: 100%;
    padding: 20px;
  }

  .value-item h3 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .value-item p {
    font-size: 16px;
  }

  /*ContactStep*/

  .contact-container {
    padding: 55px;
  }

  .contact-title {
    font-size: 30px;
    margin-bottom: 60px;
  }

  .step-wrapper {
    flex-direction: column; /* Stack elements vertically */
    align-items: center; /* Center items horizontally */
    width: 100%;
  }

  .step-wrapper:nth-child(odd),
  .step-wrapper:nth-child(even) {
    flex-direction: column; /* Override previous styles and stack vertically */
    margin-left: 0;
    margin-right: 0;
    left: 0;
  }

  .step-wrapper:nth-child(odd) .step-number {
    left: 110px;
  }

  .step-wrapper:nth-child(even) .step-number {
    left: 110px;
  }

  .step-number {
    top: 40px;
  }

  .image-container,
  .text-wrapper {
    order: 2; /* Place text-wrapper below */
    margin: 0; /* Reset any specific margins */
    left: 0; /* Reset */
    width: 100%; /* Decrease width for better fitting */
    text-align: center;
  }

  .text-wrapper {
    order: 1; /* Place text-wrapper above */
    text-align: left;
    margin-bottom: 20px;
  }
  .image-container {
    display: none;
  }

  .icon-image {
    width: 100%;
    height: auto;
  }

  .smaller-title {
    font-size: 28px;
  }

  .faq-grid {
    grid-template-columns: 1fr; /* Makes it a single column layout */
    gap: 0px; /* Adjust gap if needed for mobile */
  }

  .faq-section h2 {
    font-size: 24px; /* Adjust title size for mobile if desired */
  }
  .faq-section {
    padding: 50px;
  }

  .new-section {
    padding: 50px;
    margin-top: 30px;
  }

  .faq-item {
    margin-bottom: 10px; /* Adjust margin for mobile if desired */
    max-width: 100%; /* Adjust the width of the questions for mobile */
    margin-left: auto;
    margin-right: auto;
  }
  .icon-indicator {
    margin-left: 10px;
  }
  .faq-question {
    width: calc(
      100% - 30px
    ); /* Adjust based on how the layout looks on mobile, reducing space for the icon */
  }

  .content-section {
    width: 100%; /* Increase width on smaller screens */
    padding: 1.5rem; /* Less padding for more compact look */
  }

  .content-section h2 {
    font-size: 30px; /* Slightly smaller font-size for headers */
    margin-bottom: 1.5rem; /* Adjust margin for a more compact look */
  }

  .content-section p {
    font-size: 18px; /* Adjusting the paragraph font size for mobile screens */
    margin: 0 auto 1.5rem; /* Adjust margin for a more compact look */
  }

  .content-section-button {
    padding: 10px 30px; /* Adjusting padding for the button */
    font-size: 16px; /* Adjusting font size for the button */
  }

  .showcase-container {
    flex-direction: column;
    align-items: center; /* Center everything horizontally */
  }

  .description-section {
    order: 1; /* default is 0 */
    margin-top: 20px;
  }

  .devices {
    order: 2;
    display: flex;
    flex-direction: row; /* Keep images side by side */
    align-items: center; /* Center children vertically */
    justify-content: center; /* Center children horizontally */
    width: 100%; /* Use full width */

    overflow: hidden;
  }

  .sample-options {
    order: 3;
    display: flex; /* Ensure it's a flex container */
    justify-content: center; /* Center children horizontally */
    width: 100%; /* Use full width */
    margin-top: -20px;
    margin-left: -10px;
  }

  .slider-container {
    align-items: center; /* Horizontally center children */
    justify-content: center; /* Vertically center children */
  }

  .showcase-section {
    align-items: center;
    justify-content: center;
  }

  .phone-mockup {
    width: auto;
    height: 200px;
    margin-top: 50px;
    /* Remove any offset margins */
    /* Spacing between phone and computer mockups */
    margin-left: 100px;
  }

  .computer-mockup {
    width: 550px;
    height: auto;
    margin-left: -180px; /* Spacing between phone and computer mockups */
    margin-right: 0;
  }

  .banner-heading {
    font-size: 35px;
    margin-top: -50px;
  }
  .banner-heading1 {
    font-size: 18px;
    width: 100%;
    max-width: 100%;
  }
  .content-wrapper {
    margin-left: -50px;
  }
  .showcase-section {
    width: 100%;
  }
  .section-title {
    font-size: 30px;
  }

  .top-banner {
    background-color: transparent;
    background-attachment: scroll;
    background-image: contain;
    width: auto;
  }

  .values-title1 {
    font-size: 28px;
    margin-top: 80px;
  }
}
