/*Top banner*/
.top-banner {
  height: 630px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center; /* Center the content horizontally */
  overflow: hidden;
  z-index: 1;
  background-image: url("../../assets/images/mirrored_bannerimg.webp");
  background-size: cover; /* Cover the entire element */
  background-repeat: no-repeat; /* Do not repeat the image */
  padding-top: 80px; /* Adjusted for the header height */
  background-attachment: fixed; /* Make the background fixed during scroll */
}

.top-banner::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(135deg, #192c3b, #788fa3);
  opacity: 0.92; /* Adjust the opacity for desired transparency */
  z-index: 2;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the container */
  justify-content: center; /* Center content vertically */
  width: 100%;
  height: 100%;
  z-index: 5;
}

.heading-container {
  flex: 0 0 auto;
  color: white;
  z-index: 1;
  padding: 80px;
  width: 100%; /* Fixed width for the text container */
  max-width: 1465px; /* Optional max-width for larger screens */
  text-align: left; /* Left-align the text */
}

.banner-heading {
  font-size: 52px;
  margin-bottom: 30px;
}

.banner-heading1 {
  font-size: 22px;
  margin-bottom: 40px;
  font-weight: 500;
}

.arrow-container {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
}

.arrow-down {
  animation: bounce 1s infinite;
  color: white; /* or any other color you prefer */
  font-size: 30px; /* Adjust size as needed */
}

.arrow {
  transition: transform 0.4s ease, margin-left 0.4s ease; /* Smooth transition */
}

.articlePreview:hover .arrow {
  transform: translateX(7px); /* Move arrow slightly on hover */
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

/* Related Cases */
.relatedCases {
  margin-top: 40px;
  padding: 20px;
  text-align: center;
}

.relatedCases h2 {
  margin-bottom: 30px;
  color: #192c3b;
  font-size: 30px;
}

.relatedCasesGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.relatedCaseCard {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: transform 0.3s ease;
  border-radius: 15px;
  background-color: #33465a;
  max-height: 450px;
}

.relatedCaseCard:hover {
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  transform: scale(1.05);
}

.relatedCaseImage {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.relatedCaseLink {
  text-decoration: none;
  color: inherit;
}

.relatedCaseLink h3 {
  font-size: 24px;
  font-weight: bold;
  color: white;
  padding: 10px;
}

.relatedCaseLink p {
  font-size: 16px;
  color: white;
  padding: 10px;
  margin-bottom: 10px;
}

.relatedCaseCategories {
  position: absolute;
  top: 10px;
  left: 10px;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  z-index: 10;
}

.embeddedImage {
  max-width: 100%;
  height: auto;
  margin: 20px 0;
}

/* CasesDetails.module.css */
.caseContainer {
  margin: 30px auto;
  padding: 0 20px;
}

.caseImage {
  display: block;
  width: 80%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
  margin: 0 auto 1em;
}

.caseContent {
  margin: 20px 0;
}

.textBlock {
  margin: 20px 0;
}

.imageBlock {
  margin: 20px 0;
  text-align: center;
}

.imageBlock img {
  max-width: 100%;
  height: auto;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.videoBlock {
  margin: 20px 0;
  text-align: center;
}

.videoBlock iframe {
  width: 100%;
  height: 400px;
  border: none;
}

.categories {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.categoryTag {
  display: inline-block;
  background-color: #33465a;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.8em;
  transition: background-color 0.3s;
}

/* Loading Spinner */
.spinner {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
  margin: 50px auto;
}

.image-text-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 100px;
  padding-left: 125px;
  padding-right: 125px;
  padding-bottom: 10px;
  background-color: #ffffff;
  max-width: 1600px;
  margin: 0 auto;
  gap: 60px;
  position: relative;
  flex-wrap: wrap;
}

.image-text-container2 {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 40px;
  padding-left: 125px;
  padding-right: 125px;
  padding-bottom: 195px;
  background-color: #ffffff;
  max-width: 1600px;
  margin: 0 auto;
  gap: 60px;
  position: relative;
  flex-wrap: wrap;
}

.video-text-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 40px;
  padding-left: 125px;
  padding-right: 125px;
  padding-bottom: 100px;
  background-color: #ffffff;
  max-width: 1600px;
  margin: 0 auto;
  gap: 60px;
}

.text-section {
  font-family: "Montserrat", Arial, sans-serif;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  letter-spacing: 1.2px;
  padding: 20px;
}

.text-section h2 {
  font-family: "Montserrat", Arial, sans-serif;
  font-weight: 600;
  font-size: 32px;
  padding-bottom: 10px;
}

.text-section p {
  font-size: 16px;
  max-width: 600px;
}

.image-section-one {
  flex: 1;
  display: flex;
  justify-content: center;
  padding: 20px;
  width: 100%;
}

.image-section {
  flex: 1;
  display: flex;
  justify-content: center;
  padding: 20px;
  width: 100%;
}

.video-section {
  flex: 1;
  display: flex;
  justify-content: center;
  position: relative;
  padding: 20px;
  width: 200px;
}

.image-section img {
  width: 550px;
  height: auto;
  border-radius: 20px;
}

.image-section-one img {
  width: 500px;
  height: auto;
  border-radius: 20px;
}

.video-section video {
  width: 100%;
  height: auto;
  width: 350px;
}

.image-text-container.reversed,
.image-text-container2.reversed,
.video-text-container.reversed {
  flex-direction: row-reverse;
}

.playPauseButton {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 50%;
  color: white;
  font-size: 18px;
  padding: 10px;
  width: 50px;
  height: 50px;
  cursor: pointer;
  z-index: 10;
}

.playPauseButton:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.testimonialContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 125px;
  padding-top: 100px;
  background-color: #f7f7f7;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: auto;
}

.testimonialText {
  font-size: 18px;
  text-align: left;
  font-style: italic;
  color: #000000;
  margin: 0;
  line-height: 1.5;
  letter-spacing: 1.2px;
  max-width: 650px;
}

.testimonialText span.highlight {
  color: #192c3b;
  font-weight: bold;
}

.author {
  font-size: 16px;
  text-align: left;
  letter-spacing: 1.2px;
  margin-top: 20px;
}

.blockContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(135deg, #243e54, #51616f);
  padding: 60px;
  border-radius: 25px;
  max-width: 1275px;
  height: 450px;
  margin: 0 auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.blockTextSection {
  flex: 1;
  color: #ffffff;
  font-family: “Montserrat”, Arial, sans-serif;
  padding: 20px;
}

.blockHeading {
  font-size: 2.5rem;
  margin-bottom: 10px;
  font-family: “Montserrat”, Arial, sans-serif;
}

.blockSubHeading {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.blockCallButton {
  background-color: #ff9900;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.blockCallButton:hover {
  background-color: #e68a00;
}

.blockImageSection {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blockImage {
  max-width: 100%;
  border-radius: 10px;
}

.arrowContainer {
  display: flex;
  justify-content: center;
  position: relative;
  margin: -197px 0 -120px 0;
  padding-right: 75px;
}

@keyframes verticalFade {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.curvedArrow {
  position: relative;
  z-index: 0;
  transform: scale(0.8);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.curvedArrow.visible {
  opacity: 1;
  transform: translateY(0);
}

@media screen and (max-width: 810px) {
  .banner-heading {
    font-size: 35px;
    margin-top: -50px;
  }

  .banner-heading1 {
    font-size: 18px;
    width: 100%;
    max-width: 100%;
  }

  .top-banner {
    background-color: transparent;
    background-attachment: scroll;
    background-image: contain;
    width: auto;
  }

  .caseImage {
    width: 95%;
    height: 300px;
    margin: 10px auto;
  }

  .caseText {
    padding: 0 1em;
    text-align: left;
  }

  .arrowContainer {
    display: none;
  }

  .image-text-container,
  .image-text-container2,
  .video-text-container {
    flex-direction: column;
    align-items: center;
    text-align: left;
    padding: 20px 10px;
    padding-top: 40px;
  }

  .image-section-one img,
  .image-section img {
    width: 100%;
  }

  .reversed {
    flex-direction: column;
  }

  .image-section,
  .image-section-one,
  .video-section {
    width: 100%;
  }

  .text-section {
    width: 100%;
    padding: 10px 20px;
  }

  .video-section video {
    width: 100%;
    height: auto;
    max-width: none;
  }

  .text-section p {
    width: 100%;
  }

  .text-section h2 {
    font-size: 24px;
  }

  .image-text-container2.reversed,
  .video-text-container.reversed {
    flex-direction: column;
  }

  .banner-heading {
    margin-left: 35px;
  }

  .banner-heading1 {
    padding-left: 35px;
  }

  .my5 {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .lg6 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .mapResponsive {
    height: 150px;
    width: 100%;
  }

  .buttonCustom {
    width: 100%;
  }

  .cardTitle1,
  .cardTitle {
    font-size: 24px;
  }

  .cardText1,
  .cardText {
    font-size: 16px;
  }

  .fa2x {
    font-size: 1.5em;
  }
}

.myBtn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 99;
  border: none;
  outline: none;
  background-color: rgba(70, 121, 139, 0.3);
  color: white;
  cursor: pointer;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 24px;
  line-height: 50px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.myBtn:hover {
  background: linear-gradient(135deg, rgb(53, 70, 132), #3d58ae);
  transform: scale(1.1);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 810px) {
  .myBtn {
    bottom: 15px;
    right: 15px;
    width: 40px;
    height: 40px;
    font-size: 20px;
    line-height: 40px;
  }
}
