.languageButton,
.languageDropdown li {
  position: relative;
  color: var(--textColor);
  text-decoration: none;
  padding: 5px 0;
}

/* Add the underline */

.languageDropdown li::after {
  content: "";
  position: absolute;
  left: auto;
  bottom: 0;
  height: 2px;
  width: 0;
  background-color: var(--secondaryColor);
  transition: width 0.4s ease, left 0.4s ease;
}

.languageButton::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  height: 2px;
  width: 0;
  background-color: var(--secondaryColor);
  transition: width 0.4s ease, left 0.4s ease;
}

/* Hover effect where the underline expands outward from the center */

.languageDropdown li:hover::after {
  width: 95%;
  left: auto;
}

.languageButton:hover::after {
  width: 95%;
  left: 0;
}

/* Existing Styles */
.languageOptions {
  position: relative;
  display: flex;
  align-items: center;
  z-index: 1000;
}

.languageButton {
  background: transparent;
  border: none;
  font-size: 1.3rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1001;
  position: relative;
}

.languageButton:focus {
  outline: none;
}

.languageDropdown {
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: rgba(52, 73, 94, 0.95);
  top: 100%;
  right: 0;
  width: 100px;
  border-radius: 8px;
  overflow: hidden;
  padding: 0.5rem 0;
  max-height: 0;
  opacity: 0;
  visibility: hidden;
  transition: max-height 0.3s ease, opacity 0.3s ease, visibility 0.3s ease;
  z-index: 999999;
  pointer-events: none;
}

.showLanguageDropdown {
  max-height: 300px;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.languageDropdown li {
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  color: var(--textColor);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Hide desktop language selector on mobile */
@media only screen and (max-width: 768px) {
  .LanguageOptions {
    display: none;
  }
}

/* Hide mobile language selector on desktop */
@media only screen and (min-width: 769px) {
  .mobileLanguageOptions {
    display: none;
  }
}
