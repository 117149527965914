.myBtn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 99;
  border: none;
  outline: none;
  background-color: rgba(70, 121, 139, 0.3); /* Gradient background */
  color: white;
  cursor: pointer;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 24px; /* Font size for the icon */
  line-height: 50px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Box shadow for depth */
  transition: all 0.3s ease; /* Smooth transition for hover effect */
  display: flex;
  align-items: center;
  justify-content: center;
}

.myBtn:hover {
  background: linear-gradient(
    135deg,
    rgb(53, 70, 132),
    #3d58ae
  ); /* Invert gradient on hover */
  transform: scale(1.1); /* Slightly enlarge the button on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Enhance shadow on hover */
}
